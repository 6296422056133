import { gql } from '@apollo/client'
import { BaseUserFragment } from '../Fragments/user-fragment';

export default gql`
  query($id: Int) {
    user(id: $id) {
      ...BaseUser
    }
  }
  ${BaseUserFragment}
`;
