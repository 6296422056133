import { gql } from '@apollo/client';

export default gql`
  query {
    checkups {
      id
      countryId
      siteId
      submittedOnDate
      createdOnDate
    }
    surveyCompleteds {
      id
      countryId
      siteId
      submittedOnDate
      createdOnDate
    }
    syncs {
      id
      countryId
      createdOnDate
    }
  }
`;
