import React, { useState, useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ButtonToolbar from '../../common/ButtonToolbar';
import surveyTemplatePageLoadQuery from '../../../graphql/ManageSurvey/page-load-query';
import UPDATE_SURVEY_TEMPLATE_MUTATION from '../../../graphql/ManageSurvey/update-mutation';
import CREATE_LANGUAGE_MUTATION from '../../../graphql/Language/create-language-mutation';
import MoreVertMenu from '../../common/MoreVertMenu';
import AddLanguageModal from '../../modals/AddLanguageModal';
import _ from 'lodash';
import { flowRight as compose } from 'lodash';
import { useQuery, useMutation } from '@apollo/client';
import useToaster from 'state/hooks/use-toaster';

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    width: '100%'
  },
  topTextField: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
    width: 200,
    position: 'relative',
    top: -27,
    left: 30
  },
  category: {
    marginLeft: 40
  },
  option: {
    marginLeft: 80
  },
  domainTextField: {
    width: '40%'
  },
  categoryTextField: {
    width: '60%'
  },
  optionTextField: {
    width: '80%'
  }
});
const DEFAULT_LANGUAGE = 'english';
const ENGLISH = { id: 0, name: 'English', jsonPropertyName: 'english' };

const parseSurveyTemplateJson = surveyTemplate => {
  // deserialize here because graphql doesn't like the dynamic Javascript object sent back
  if (surveyTemplate.domains && surveyTemplate.domains.length) {
    for (const x of surveyTemplate.domains) {
      x.json = JSON.parse(x.json);

      for (const y of x.categories) {
        y.json = JSON.parse(y.json);

        for (const z of y.options) {
          z.json = JSON.parse(z.json);
        }
      }
    }
  }

  return surveyTemplate;
};

const prepareSurveyTemplateForUpdate = surveyTemplate => {
  const clonedSurveyTemplate = _.cloneDeep(surveyTemplate);

  // deserialize here because graphql doesn't like the dynamic Javascript object sent back
  if (clonedSurveyTemplate.domains && clonedSurveyTemplate.domains.length) {
    delete clonedSurveyTemplate.__typename;

    for (const x of clonedSurveyTemplate.domains) {
      x.json = JSON.stringify(x.json);
      delete x.__typename;

      for (const y of x.categories) {
        y.json = JSON.stringify(y.json);
        delete y.__typename;

        for (const z of y.options) {
          z.json = JSON.stringify(z.json);
          delete z.__typename;
        }
      }
    }
  }

  return clonedSurveyTemplate;
};

const ManageSurveyPage = props => {
  const [, ToasterContext] = useToaster();
  const [state, setState] = useState<any>({});
  const [language, setLanguage] = useState(DEFAULT_LANGUAGE);
  const { data } = useQuery(surveyTemplatePageLoadQuery, {
    variables: { surveyTemplateId: null },
    fetchPolicy: 'no-cache'
  });
  const [updateSurveyTemplate] = useMutation(UPDATE_SURVEY_TEMPLATE_MUTATION);
  const [createLanguage] = useMutation(CREATE_LANGUAGE_MUTATION);

  useEffect(() => {
    if (data) {
      // set on load
      setState({
        isAddLanguageModalOpen: false,
        moreVertMenuOpen: false,
        surveyTemplate: parseSurveyTemplateJson(data.surveyTemplate),
        languages: [...data.languages, ENGLISH]
      });
    }
  }, [data]);

  const getValue = entity => {
    if (language === DEFAULT_LANGUAGE) return entity.name;

    return entity.json[language] || '';
  };

  const handleSubmit = async () => {
    const { surveyTemplate } = state;

    try {
      await updateSurveyTemplate({
        variables: { input: prepareSurveyTemplateForUpdate(surveyTemplate) }
      });
      // not updating page for now - no time... can call `refetch` on client?

      ToasterContext.showSuccessToaster({ message: 'Survey Template updated!' });
    } catch (err) {
      ToasterContext.showErrorToaster({ message: 'Uh oh! Unexpected error occurred.' });
    }
  };

  const handleLanguageChange = e => {
    setLanguage(e.target.value);
  };

  const saveFormChanges = (surveyTemplate, domains) => {
    setState({ ...state, surveyTemplate: { ...surveyTemplate, domains } });
  };

  const handleDomainChange = domainIndex => e => {
    const {
      surveyTemplate,
      surveyTemplate: { domains }
    } = state;

    if (language === DEFAULT_LANGUAGE) {
      domains[domainIndex].name = e.target.value;
    } else {
      domains[domainIndex].json[language] = e.target.value;
    }

    saveFormChanges(surveyTemplate, domains);
  };

  const handleCategoryChange = (domainIndex, categoryIndex) => e => {
    const {
      surveyTemplate,
      surveyTemplate: { domains }
    } = state;

    if (language === DEFAULT_LANGUAGE) {
      domains[domainIndex].categories[categoryIndex].name = e.target.value;
    } else {
      domains[domainIndex].categories[categoryIndex].json[language] = e.target.value;
    }

    saveFormChanges(surveyTemplate, domains);
  };

  const handleOptionChange = (domainIndex, categoryIndex, optionIndex) => e => {
    const {
      surveyTemplate,
      surveyTemplate: { domains }
    } = state;

    if (language === DEFAULT_LANGUAGE) {
      domains[domainIndex].categories[categoryIndex].options[optionIndex].name = e.target.value;
    } else {
      domains[domainIndex].categories[categoryIndex].options[optionIndex].json[language] =
        e.target.value;
    }

    saveFormChanges(surveyTemplate, domains);
  };

  const renderDomains = domains => {
    const { classes } = props;

    return domains.map((d, index) => (
      <div key={`${d.id}-${index}`}>
        <ExpansionPanel style={{ marginTop: 30 }} defaultExpanded={index === 0 ? true : false}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h5" component="h3">
              {d.name}
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography style={{ width: '100%' }} component="div">
              <TextField
                label="Domain"
                value={getValue(d)}
                onChange={handleDomainChange(index)}
                margin="normal"
                className={classes.domainTextField}
              />
              {renderDomainCategories(d.categories, index)}
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </div>
    ));
  };

  const renderDomainCategories = (categories, domainIndex) => {
    const { classes } = props;

    return categories.map((c, index) => (
      <div key={`${c.id}-${domainIndex}-${index}`} className={classes.category}>
        <TextField
          label="Category"
          value={getValue(c)}
          onChange={handleCategoryChange(domainIndex, index)}
          margin="normal"
          className={classes.categoryTextField}
        />
        {renderCategoryOptions(c.options, domainIndex, index)}
      </div>
    ));
  };

  const renderCategoryOptions = (options, domainIndex, categoryIndex) => {
    const { classes } = props;

    return options.map((o, index) => (
      <div key={`${o.id}-${domainIndex}-${categoryIndex}-${index}`} className={classes.option}>
        <TextField
          label="Option"
          value={getValue(o)}
          onChange={handleOptionChange(domainIndex, categoryIndex, index)}
          margin="normal"
          className={classes.optionTextField}
        />
      </div>
    ));
  };

  const handlePageClick = () => {
    setState({ ...state, moreVertMenuOpen: false });
  };

  const handleOpenVertMenu = e => {
    setState({ ...state, moreVertMenuOpen: true });
  };

  const handleVertMenuItemClick = ({ name, title, value }) => {
    const isAddLanguageModalOpen = name === 'language';

    setState({
      ...state,
      moreVertMenuOpen: false,
      ...(isAddLanguageModalOpen && { isAddLanguageModalOpen })
    });
  };

  const handleCloseAddLanguageModal = () => {
    setState({ ...state, isAddLanguageModalOpen: false });
  };

  const handleAddLanguage = async ({ language }) => {
    setState({ ...state, isAddLanguageModalOpen: false });

    try {
      const {
        data: { createLanguage: languages }
      } = await createLanguage({
        variables: { input: { name: language } }
      });
      setState({ ...state, languages: [...languages, ENGLISH] });
      // not updating page for now - no time... can call `refetch` on client?

      ToasterContext.showSuccessToaster({ message: 'Language successfully created!' });
    } catch (err) {
      ToasterContext.showErrorToaster({ message: 'Uh oh! Unexpected error occurred.' });
    }
  };

  const { classes } = props;
  const { surveyTemplate, languages = [], isAddLanguageModalOpen } = state;
  return (
    <React.Fragment>
      <AddLanguageModal
        open={isAddLanguageModalOpen}
        onCancel={handleCloseAddLanguageModal}
        onSubmit={handleAddLanguage}
        languages={languages}
      />
      <div onClick={handlePageClick}>
        <Paper className={classes.root} elevation={1}>
          <Typography variant="h5" component="h3">
            <span>
              Manage Survey
              <TextField
                style={{ marginRight: 35 }}
                select
                label="Languages"
                value={language}
                onChange={handleLanguageChange}
                SelectProps={{
                  MenuProps: {
                    // className: classes.menu,
                  }
                }}
                margin="normal"
                className={classes.topTextField}
              >
                {languages.map((x, index) => (
                  <MenuItem key={index} value={x.jsonPropertyName}>
                    {x.name}
                  </MenuItem>
                ))}
              </TextField>
              <MoreVertMenu
                open={!!state.moreVertMenuOpen}
                handleOpen={handleOpenVertMenu}
                handleMenuItemClick={handleVertMenuItemClick}
                items={[{ name: 'language', title: 'Add Language' }]}
              ></MoreVertMenu>
            </span>
          </Typography>
          <div style={{ marginBottom: 25 }}>{renderDomains(surveyTemplate?.domains ?? [])}</div>
          <ButtonToolbar
            style={{ position: 'fixed', top: 100, right: 100 }}
            handleSubmit={handleSubmit}
          />
        </Paper>
      </div>
    </React.Fragment>
  );
};

export default compose(withStyles(styles as any))(ManageSurveyPage);
