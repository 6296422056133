import { gql } from '@apollo/client'

export const BaseSurveyTemplateEntityFragment = gql`
  fragment BaseSurveyTemplate on SurveyTemplate {
    id
    name
    userIdCreatedBy
    isDefault
    createdOnDate
    domains {
      surveyTemplateId
      ...BaseDomain
      categories {
        domainId
        ...BaseCategory
        options {
          categoryId
          scoreValue
          ...BaseOption
        }
      }
    }
  }
  fragment BaseDomain on Domain {
    id
    name
    json
  }
  fragment BaseCategory on Category {
    id
    name
    json
  }
  fragment BaseOption on Option {
    id
    name
    json
  }
`;
