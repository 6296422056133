import { useState } from 'react';
import { useQuery } from '@apollo/client';
import { useHistory } from 'react-router';
import GET_ME from '../../graphql/me/get-me-query';
import AuthService from '../../services/auth';

type User = any;
export type UserStoreState = {
  me?: User;
  isAuthenticated: boolean;
  isAuthenticating: boolean;
  refetching: boolean;
};

export type UserStoreDispatch = { logout: () => void; refetchUser: () => void };
export type UserStore = [UserStoreState, UserStoreDispatch];

const useUserStore = (): UserStore => {
  const { data, loading, error, refetch } = useQuery(GET_ME);
  const [refetching, setRefetching] = useState(false);
  const history = useHistory();
  const isAuthenticated = data?.me && !error ? true : false;

  const logout = () => {
    AuthService.deleteToken();
    history.push('/login');
  };

  const refetchUser = async () => {
    setRefetching(true);
    await refetch();
    setRefetching(false);
  };

  return [
    {
      me: data?.me,
      isAuthenticated: refetching ? true : isAuthenticated,
      isAuthenticating: refetching ? true : loading,
      refetching
    },
    { logout, refetchUser }
  ];
};

export default useUserStore;
