import { gql } from '@apollo/client';
import { BaseChildAlertFragment } from '../Fragments/child-alert-fragment';

export default gql`
  query($countryId: Int) {
    childAlerts(countryId: $countryId) {
      ...BaseChildAlert
    }
  }
  ${BaseChildAlertFragment}
`;
