export const validate = (input, formControls) => {
  let isValid = true;
  const errors = Object.entries(input).reduce((acc, [key, value]) => {
    if (formControls[key]) {
      if (formControls[key].test && !formControls[key].test(value, input)) {
        acc[key] = { ...formControls[key], err: true };
        isValid = false;
      } else acc[key] = { ...formControls[key], err: false };
    }
    return acc;
  }, {});

  return !isValid ? errors : null;
};

export const sanitizeJson = value => {
  return typeof value === 'object' && value !== null ? JSON.stringify(value) : value || '';
};
