import React, { useState, useEffect } from 'react';
import useUserStore from 'state/hooks/use-user-store';
import { withStyles } from '@material-ui/core/styles';
import { flowRight as compose } from 'lodash';
import { useQuery } from '@apollo/client';

import { now } from '../../../util/years-util';
import { YEARS, TABS } from './util/dashboard-variables';
import {
  createChartDataEntityMap,
  loadChartDataByCountryId,
  loadChartDataBySiteId
} from './util/dashboard-util';

import ALL_SITES_BY_PERMISSIONS_QUERY from '../../../graphql/Dashboard/all-sites-by-permissions-query';
import DASHBOARD_REPORTS_QUERY from '../../../graphql/Dashboard/dashboard-reports-query';

import AppBar from '@material-ui/core/AppBar';
import BaseMonthlyChart from './charts/BaseMonthlyChart';
import CommonLoader from '../../common/CommonLoader';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  tabRoot: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper
  },
  tabAppbar: {
    backgroundColor: 'white',
    color: theme.palette.primary.main
  }
});

const DashboardReports = props => {
  const { classes = {} } = props;

  const [{ me }] = useUserStore();
  const userCountries = me ? me.countries.map(o => ({ ...o })) : [];
  userCountries.sort((a, b) => (a.name < b.name ? -1 : 1));

  const [state, setState] = useState({
    tabValue: TABS.SURVEYS,
    sites: null,
    selectedCountryId: userCountries && userCountries[0] && userCountries[0].id,
    selectedYear: now(),
    selectedSiteId: null
  } as any);

  const { data: allSitesData } = useQuery(ALL_SITES_BY_PERMISSIONS_QUERY);
  const { loading: isReportsLoading, data: reportsData } = useQuery(DASHBOARD_REPORTS_QUERY);

  useEffect(() => {
    const allSitesByPermissions = allSitesData && allSitesData.allSitesByPermissions;

    if (allSitesByPermissions) {
      const sites = allSitesByPermissions.filter(
        s => state.selectedCountryId && s && s.countryId && s.countryId === state.selectedCountryId
      );
      const selectedSiteId = sites && sites.length === 1 ? sites[0].id : state.selectedSiteId;
      setState({ ...state, sites, selectedSiteId });
    }

    if (allSitesByPermissions && reportsData) {
      const { syncs = [], checkups = [], surveyCompleteds = [] } = reportsData;

      const selectedCountryId = state.selectedCountryId;
      const selectedYear = state.selectedYear;

      const chartEntityMap = createChartDataEntityMap({
        entities: syncs,
        countries: me.countries,
        sites: allSitesByPermissions
      });
      const checkupCountryHash = createChartDataEntityMap({
        entities: checkups,
        countries: me.countries,
        sites: allSitesByPermissions,
        keyByDate: 'submittedOnDate'
      });
      const completedSurveyCountryHash = createChartDataEntityMap({
        entities: surveyCompleteds,
        countries: me.countries,
        sites: allSitesByPermissions,
        keyByDate: 'submittedOnDate'
      });

      const syncChartData = loadChartDataByCountryId(
        chartEntityMap,
        selectedCountryId,
        selectedYear
      );
      const checkupChartData = loadChartDataByCountryId(
        checkupCountryHash,
        selectedCountryId,
        selectedYear
      );
      const completedSurveyChartData = loadChartDataByCountryId(
        completedSurveyCountryHash,
        selectedCountryId,
        selectedYear
      );

      setState(state => ({
        ...state,
        chartEntityMap,
        checkupCountryHash,
        completedSurveyCountryHash,
        syncChartData,
        checkupChartData,
        completedSurveyChartData
      }));
    }
  }, [me.countries, allSitesData, reportsData]);

  const loadChartData = (year, countryId, siteId) => {
    const syncChartData = siteId
      ? loadChartDataBySiteId(state.chartEntityMap, siteId, year)
      : loadChartDataByCountryId(state.chartEntityMap, countryId, year);
    const checkupChartData = siteId
      ? loadChartDataBySiteId(state.checkupCountryHash, siteId, year)
      : loadChartDataByCountryId(state.checkupCountryHash, countryId, year);
    const completedSurveyChartData = siteId
      ? loadChartDataBySiteId(state.completedSurveyCountryHash, siteId, year)
      : loadChartDataByCountryId(state.completedSurveyCountryHash, countryId, year);

    return {
      syncChartData,
      checkupChartData,
      completedSurveyChartData
    };
  };

  const loadCountryFromId = selectedCountryId => {
    const sites = allSitesData.allSitesByPermissions.filter(
      s => selectedCountryId && s && s.countryId && s.countryId === selectedCountryId
    );
    const selectedSiteId = sites && sites.length === 1 ? sites[0].id : null;

    return {
      sites,
      selectedSiteId,
      selectedCountryId
    };
  };

  const handleTabChange = (e, tabValue) => {
    setState({ ...state, tabValue });
  };

  const handleCountryChange = e => {
    const selectedCountryId = e.target.value && parseInt(e.target.value, 10);
    const { sites, selectedSiteId } = loadCountryFromId(selectedCountryId);
    setState({
      ...state,
      selectedCountryId,
      sites,
      selectedSiteId,
      ...loadChartData(state.selectedYear, selectedCountryId, selectedSiteId)
    });
  };

  const handleSiteChange = e => {
    const selectedSiteId = e.target.value && parseInt(e.target.value, 10);
    setState({
      ...state,
      selectedSiteId,
      ...loadChartData(state.selectedYear, state.selectedCountryId, selectedSiteId)
    });
  };

  const handleYearChange = e => {
    const selectedYear = e.target.value;
    setState({
      ...state,
      selectedYear,
      ...loadChartData(selectedYear, state.selectedCountryId, state.selectedSiteId)
    });
  };

  const render = () => {
    const {
      syncChartData,
      checkupChartData,
      completedSurveyChartData,
      sites,
      selectedCountryId,
      selectedSiteId,
      selectedYear,
      tabValue
    } = state;

    return (
      <div className={classes.tabRoot}>
        <AppBar className={classes.tabAppbar} position="static">
          <Tabs value={tabValue} onChange={handleTabChange}>
            <Tab label="Surveys" />
            <Tab label="Checkups" />
            <Tab label="Syncs" />
          </Tabs>
        </AppBar>

        <Grid
          style={{ paddingTop: 10, paddingBottom: 10, paddingLeft: 24, paddingRight: 24 }}
          container
        >
          <Grid item md={3}>
            <TextField
              style={{ width: 'calc(100% - 30px)' }}
              select
              disabled={isReportsLoading}
              label="Year"
              name="selectedYear"
              value={selectedYear || ''}
              onChange={handleYearChange}
              SelectProps={{
                MenuProps: {}
              }}
              margin="normal"
              className={classes.textField}
            >
              {YEARS.map(x => (
                <MenuItem key={x} value={x}>
                  {x}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item md={3}>
            <TextField
              style={{ width: 'calc(100% - 30px)' }}
              select
              label="Country"
              disabled={isReportsLoading || userCountries.length <= 0}
              value={selectedCountryId || ''}
              onChange={handleCountryChange}
              SelectProps={{
                MenuProps: {}
              }}
              margin="normal"
              className={classes.textField}
            >
              {userCountries?.map(x => (
                <MenuItem key={x.id} value={x.id}>
                  {x.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          {state.tabValue !== TABS.SYNCS && (
            <Grid item md={6}>
              <TextField
                style={{ width: 'calc(100% - 30px)' }}
                select
                label="Site"
                helperText={!sites || sites.length <= 0 ? 'Disabled' : null}
                disabled={isReportsLoading || !sites || sites.length <= 0}
                value={selectedSiteId || ''}
                onChange={handleSiteChange}
                SelectProps={{
                  MenuProps: {}
                }}
                margin="normal"
                className={classes.textField}
              >
                {!sites
                  ? []
                  : sites?.map(x => (
                      <MenuItem key={x.id} value={x.id}>
                        {x.name}
                      </MenuItem>
                    ))}
              </TextField>
            </Grid>
          )}
        </Grid>

        {isReportsLoading && (
          <div style={{ padding: 20 }}>
            <CommonLoader text="Loading reports..." />
          </div>
        )}
        {!isReportsLoading && (
          <Typography component="div" style={{ padding: 20 }}>
            {isReportsLoading && <CommonLoader />}

            {!isReportsLoading && tabValue === TABS.SURVEYS && (
              <BaseMonthlyChart
                chartData={completedSurveyChartData}
                label={'Number of Completed Surveys'}
              />
            )}

            {!isReportsLoading && tabValue === TABS.CHECKUPS && (
              <BaseMonthlyChart
                chartData={checkupChartData}
                label={'Number of Completed Checkups'}
              />
            )}

            {!isReportsLoading && tabValue === TABS.SYNCS && (
              <BaseMonthlyChart chartData={syncChartData} label={'Number of Completed Syncs'} />
            )}
          </Typography>
        )}
      </div>
    );
  };

  return render();
};

export default compose(withStyles(styles))(DashboardReports);
