import React from 'react';
import MaterialTable from 'material-table';
import { tableIcons } from './table-icons';

const CommonTable = ({
  columns = [],
  data = [],
  title = null,
  options = {},
  loading = false,
  ...restProps
}) => {
  return (
    <MaterialTable
      icons={tableIcons}
      title={title || ''}
      columns={columns}
      data={data}
      isLoading={loading}
      options={{ emptyRowsWhenPaging: false, pageSize: 50, pageSizeOptions: [10, 50], ...options }}
      {...restProps}
    />
  );
};

export default CommonTable;
