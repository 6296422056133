import React, { useState, useEffect } from 'react';

import useToaster from 'state/hooks/use-toaster';
import { useMutation, useLazyQuery } from '@apollo/client';

import CHILD_ALERTS_QUERY from '../../../graphql/Dashboard/child-alerts-query';
import UPDATE_CHILD_ALERTS from '../../../graphql/ChildAlert/acknowledge-child-alert-mutation';

import { exportData } from './util/dashboard-util';

import Button from '@material-ui/core/Button';
import ChildAlertTable from '../../common/ChildAlertTable';
import CommonLoader from '../../common/CommonLoader';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import useUserStore from 'state/hooks/use-user-store';
import { MenuItem, TextField } from '@material-ui/core';

const DashboardChildAlerts = props => {
  const [, ToasterContext] = useToaster();
  const [getChildAlerts, { loading, data, refetch: refetchChildAlerts }] = useLazyQuery(
    CHILD_ALERTS_QUERY
  );
  const [updateChildAlerts] = useMutation(UPDATE_CHILD_ALERTS);
  const [state, setState] = useState({
    countries: [],
    selectedCountryId: null
  } as any);

  const [{ me }] = useUserStore();
  const countries = me ? me.countries.map(o => ({ ...o })) : [];
  countries.sort((a, b) => (a.name < b.name ? -1 : 1));

  const loadChildAlerts = () => {
    return getChildAlerts(
      state.selectedCountryId ? { variables: { countryId: state.selectedCountryId } } : null
    );
  };

  useEffect(() => {
    if (state.selectedCountryId) {
      loadChildAlerts();
    }
  }, [state.selectedCountryId]);

  const handleAcknowledgeChildAlerts = async alerts => {
    try {
      await updateChildAlerts({
        variables: { childAlertIds: alerts.map(x => x.id) }
      });
      // Re-retrieve full list of alerts.
      refetchChildAlerts();
      ToasterContext.showSuccessToaster({ message: 'Saved successfully!' });
    } catch (err) {
      ToasterContext.showErrorToaster({ message: 'Uh oh! Unexpected error occurred.' });
    }
  };

  const handleExpansionPanelChange = (e, expanded) => {
    if (expanded) {
      loadChildAlerts();
    }
  };

  const handleCountryChange = e => {
    const selectedCountryId = e.target.value || null;

    if (selectedCountryId) {
      setState(s => {
        return { ...s, selectedCountryId };
      });
    }
  };

  const handleCountryClear = () => {
    getChildAlerts();
    setState(s => {
      return { ...s, selectedCountryId: null };
    });
  };

  const render = () => {
    const { classes = {} } = props;
    const childAlerts = (data?.childAlerts ?? []).map(x => ({ ...x }));
    const { selectedCountryId } = state;

    return (
      <div>
        <ExpansionPanel style={{ marginTop: 30 }} onChange={handleExpansionPanelChange}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h5" component="h3">
              Child Alerts
            </Typography>
          </ExpansionPanelSummary>
          {countries && countries.length > 1 && (
            <div className="dashboard__country-select-wrapper">
              <TextField
                select
                disabled={!countries || !countries.length}
                value={selectedCountryId || ''}
                onChange={handleCountryChange}
                SelectProps={{ MenuProps: {} }}
                label="Country"
                margin="normal"
                className={`country-select${classes.textField ? ' ' + classes.textField : ''}`}
              >
                {countries?.map(x => (
                  <MenuItem key={x.id} value={x.id}>
                    {x.name}
                  </MenuItem>
                ))}
              </TextField>
              {selectedCountryId && (
                <Button
                  onClick={handleCountryClear}
                  color="secondary"
                  variant="contained"
                  type="submit"
                >
                  Clear Filter
                </Button>
              )}
            </div>
          )}
          <ExpansionPanelDetails>
            {loading && <CommonLoader />}
            {!loading && (
              <Typography style={{ width: '100%' }} component="div">
                {childAlerts && (
                  <ChildAlertTable
                    options={{
                      search: false,
                      exportButton: true,
                      exportCsv: columns => exportData(columns, childAlerts, 'childAlerts')
                    }}
                    handleAcknowledge={handleAcknowledgeChildAlerts}
                    data={childAlerts}
                  />
                )}
              </Typography>
            )}
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </div>
    );
  };

  return render();
};

export default DashboardChildAlerts;
