import { gql } from '@apollo/client';

export default gql`
  query($role: RoleEnum) {
    allCountriesByPermissions {
      id
      name
    }
    allSitesByPermissions {
      id
      countryId
      name
    }
    allRegionsByPermissions {
      id
      countryId
      siteId
      name
    }
    allVillagesByPermissions {
      id
      countryId
      regionId
      name
    }
    usersByRole(role: $role) {
      id
      name
      countries {
        id
      }
    }
  }
`;
