import React from 'react';
import { Bar } from 'react-chartjs-2';

class SyncChart extends React.Component<any, any> {
  render() {
    const { chartData, label } = this.props;
    const data = {
      labels: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
      ],
      datasets: [
        {
          label: label,
          backgroundColor: 'rgba(255,99,132,0.2)',
          borderColor: 'rgba(255,99,132,1)',
          borderWidth: 1,
          hoverBackgroundColor: 'rgba(255,99,132,0.4)',
          hoverBorderColor: 'rgba(255,99,132,1)',
          data: chartData && chartData.length ? chartData : [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        }
      ]
    };

    return (
      <div>
        <Bar
          data={data}
          options={{
            maintainAspectRatio: false
          }}
        />
      </div>
    );
  }
}

export default SyncChart;
