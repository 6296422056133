import { setContext } from 'apollo-link-context';
import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { CONSTANT } from '../constant';
import AuthService from '../services/auth';

const httpLink: any = createHttpLink({
  uri: CONSTANT.GRAPHQL_URL
});
const authLink: any = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = AuthService.getToken();
  // return the headers to the context so httpLink can read them

  return {
    headers: {
      ...headers,
      gfappsource: 'gf-data-web',
      authorization: token ? `Bearer ${token}` : ''
    }
  };
});

export const client = new ApolloClient({
  link: authLink.concat(httpLink as any),
  cache: new InMemoryCache()
});
