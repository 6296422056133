import 'whatwg-fetch';
import * as queryString from 'query-string';
import { CONSTANT } from '../constant';
import AuthService from './auth';

const HEADERS = {
  'Content-Type': 'application/json'
};
const makeAuthBearerHeader = token => ({
  Authorization: 'Bearer ' + token
});

class ApiService {
  _fetch(route, options = {} as any) {
    let url = CONSTANT.REST_URL + route;
    const token = AuthService.getToken();

    if (token) {
      options.headers = { ...(options.headers || {}), ...makeAuthBearerHeader(token) };
    }

    if (options.query) {
      url = `${url}?${queryString.stringify(options.query)}`;
    }

    return fetch(url, options)
      .then(this.checkStatus)
      .then(response => {
        return response && response.json();
      });
  }

  post(route, body, headers = HEADERS) {
    const requestBody = JSON.stringify(body) || '{}';
    const options = {
      method: 'POST',
      body: requestBody,
      headers
    };

    return this._fetch(route, options);
  }

  get(route, query = {}) {
    const options = {
      method: 'GET',
      headers: HEADERS,
      query
    };

    return this._fetch(route, options);
  }

  put(route) {
    const options = {
      method: 'PUT',
      headers: HEADERS
    };

    return this._fetch(route, options);
  }

  checkStatus = response => {
    if (response.status >= 200 && response.status < 300) {
      return response;
    } else {
      const error = new Error(response.errMsg);

      throw error;
    }
  };
}

export default new ApiService();
