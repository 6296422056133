import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { flowRight as compose } from 'lodash';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import ButtonToolbar from '../../common/ButtonToolbar';
import Checkbox from '@material-ui/core/Checkbox';
import ChildrenPageLoadQuery from '../../../graphql/Children/page-load-query';
import FETCH_ENTITIES from '../../../graphql/Children/children-search-query';
import ASSIGN_CARE_WORKER_MUTATION from '../../../graphql/Children/assign-children-supervisor-mutation';
import { stringify, parse } from 'query-string';
import ChildrenTable from '../../common/ChildrenTable';
import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import { useHistory, useRouteMatch } from 'react-router';
import useToaster from 'state/hooks/use-toaster';
import { exportCsv } from 'util/export-util';
import { maybeDate } from 'util/maybe';

const exportData = (columns, data) => {
  const jsonData = data.map(x => ({
    Child: x.name,
    'Care Worker': x.supervisorName,
    'Child No': x.outsideSystemNo,
    'Last Checkup': maybeDate(x.lastCheckupDate),
    'Last Survey': maybeDate(x.lastSurveyDate),
    Country: x.countryName,
    Site: x.siteName,
    Region: x.regionName,
    Village: x.villageName
  }));
  exportCsv(jsonData, 'gfdata-export-children.csv');
};

const makeSites = (countryId, allSites) => {
  const sites = countryId ? allSites.filter(x => x.countryId === countryId) : allSites;
  return sites;
};

const makeRegions = (countryId, siteId, allRegions) => {
  if (siteId) {
    return allRegions.filter(x =>
      Array.isArray(siteId) ? siteId.includes(x.siteId) : x.siteId === siteId
    );
  } else if (countryId) {
    return allRegions.filter(x => x.countryId === countryId);
  }
  return allRegions;
};

const makeVillages = (countryId, regionId, allVillages) => {
  if (regionId) {
    return allVillages.filter(x =>
      Array.isArray(regionId) ? regionId.includes(x.regionId) : x.regionId === regionId
    );
  } else if (countryId) {
    return allVillages.filter(x => x.countryId === countryId);
  }
  return allVillages;
};

const sanitize = value => {
  return typeof value === 'string' && typeof +value === 'number' ? +value : value;
};

const alphabetize = (list, prop = 'name') => {
  return list.slice().sort((a, b) => (a[prop] < b[prop] ? -1 : 1));
};

const ManageChildrenPage = props => {
  const [, ToasterContext] = useToaster();
  const history = useHistory();
  const match = useRouteMatch();

  const { data } = useQuery(ChildrenPageLoadQuery, {
    variables: { role: 'CAREWORKER' }
  });
  const [
    lazyFetchEntities,
    { data: { childrenSearch: children = [] } = {}, loading }
  ] = useLazyQuery(FETCH_ENTITIES, { fetchPolicy: 'network-only' });
  const [assignSupervisor] = useMutation(ASSIGN_CARE_WORKER_MUTATION);
  const entities = useMemo(() => children.map(x => ({ ...x })), [children]);
  const {
    allCountriesByPermissions = [],
    allSitesByPermissions = [],
    allRegionsByPermissions = [],
    allVillagesByPermissions = [],
    usersByRole = []
  } = data || {};

  const [selectCountries, setSelectCountries] = useState([]);
  const [selectSites, setSelectSites] = useState([]);
  const [selectRegions, setSelectRegions] = useState([]);
  const [selectVillages, setSelectVillages] = useState([]);

  const [selectedCountryId, setSelectedCountryId] = useState(null);
  const [selectedSiteId, setSelectedSiteId] = useState(null);
  const [selectedRegionId, setSelectedRegionId] = useState(null);
  const [selectedVillageId, setSelectedVillageId] = useState(null);
  const [selectedSupervisorId, setSelectedSupervisorId] = useState(null);

  const [outsideSystemNo, setOutsideSystemNo] = useState('');
  const [childName, setChildName] = useState('');

  const [excludeAssignedChildren, setExcludeAssignedChildren] = useState(null);

  const [hasSearch, setHasSearchState] = useState(false);

  const fetchEntities = useCallback(async () => {
    const searchParams = parse(history.location.search) || {};

    const newSelectedCountryId = searchParams.countryId ? sanitize(searchParams.countryId) : null;
    const newSelectedSiteId = searchParams.siteId ? sanitize(searchParams.siteId) : null;
    const newSelectedRegionId = searchParams.regionId ? sanitize(searchParams.regionId) : null;
    const newSelectedVillageId = searchParams.villageId ? sanitize(searchParams.villageId) : null;

    const newSelectSites = makeSites(newSelectedCountryId, allSitesByPermissions);
    const newSelectRegions = makeRegions(
      newSelectedCountryId,
      newSelectedSiteId,
      allRegionsByPermissions
    );
    const newSelectVillages = makeVillages(
      newSelectedCountryId,
      newSelectedRegionId || newSelectRegions.map(r => r.id),
      allVillagesByPermissions
    );

    const newChildName = searchParams.childName ? '' + searchParams.childName : '';
    const newOutsideSystemNo = searchParams.outsideSystemNo
      ? '' + searchParams.outsideSystemNo
      : '';

    const newExcludeAssignedChildren =
      searchParams.showOnlyUnassignedChildren &&
      sanitize(searchParams.showOnlyUnassignedChildren) === 1;

    // Set selected values from query, and update options lists based on selections.
    if (allCountriesByPermissions) {
      setSelectCountries(alphabetize(allCountriesByPermissions));
      setSelectedCountryId(newSelectedCountryId);
    }
    if (allSitesByPermissions) {
      setSelectSites(alphabetize(newSelectSites));
      setSelectedSiteId(newSelectedSiteId);
    }
    if (allRegionsByPermissions) {
      setSelectRegions(alphabetize(newSelectRegions));
      setSelectedRegionId(newSelectedRegionId);
    }
    if (allVillagesByPermissions) {
      setSelectVillages(alphabetize(newSelectVillages));
      setSelectedVillageId(newSelectedVillageId);
    }

    if (newChildName) {
      setChildName(newChildName);
    }
    if (newOutsideSystemNo) {
      setOutsideSystemNo(newOutsideSystemNo);
    }

    if (newExcludeAssignedChildren) {
      setExcludeAssignedChildren(true);
    }

    // Update if we have a search (based on query params).
    const willHaveSearch = determineHasSearch({
      selectedCountryId: newSelectedCountryId,
      selectedSiteId: newSelectedSiteId,
      selectedRegionId: newSelectedRegionId,
      selectedVillageId: newSelectedVillageId,
      childName: newChildName,
      outsideSystemNo: newOutsideSystemNo
    });
    setHasSearchState(willHaveSearch);

    // Potentially fetch children?
    if (willHaveSearch) {
      lazyFetchEntities({
        variables: {
          ...(newSelectedCountryId && { countryId: newSelectedCountryId }),
          ...(newSelectedSiteId && { siteId: newSelectedSiteId }),
          ...(newSelectedRegionId && { regionId: newSelectedRegionId }),
          ...(newSelectedVillageId && { villageId: newSelectedVillageId }),
          ...(newChildName && { childName: newChildName }),
          ...(newOutsideSystemNo && { outsideSystemNo: newOutsideSystemNo }),
          ...(newExcludeAssignedChildren && { showOnlyUnassignedChildren: true })
        }
      });
    }
  }, [
    allCountriesByPermissions,
    allRegionsByPermissions,
    allSitesByPermissions,
    allVillagesByPermissions,
    history.location.search,
    lazyFetchEntities
  ]);

  // Fetch entities (query params, select options, children) on page load.
  useEffect(() => {
    if (data || (history.location.search && data)) {
      fetchEntities();
    }
  }, [history.location.search, data, fetchEntities]);

  const handleSubmit = () => {
    history.push(
      `${match.url}?${stringify({
        countryId: selectedCountryId,
        siteId: selectedSiteId,
        regionId: selectedRegionId,
        villageId: selectedVillageId,
        ...(childName && { childName }),
        ...(outsideSystemNo && { outsideSystemNo }),
        ...(excludeAssignedChildren && { showOnlyUnassignedChildren: 1 })
      })}`
    );
  };

  const handleClear = () => {
    history.push(match.url);
  };

  const updateLocation = ({ countryId, siteId, regionId, villageId }) => {
    const hasCountryChange = typeof countryId !== 'undefined' && countryId !== selectedCountryId;
    const hasSiteChange = typeof siteId !== 'undefined' && siteId !== selectedSiteId;
    const hasRegionChange = typeof regionId !== 'undefined' && regionId !== selectedRegionId;
    const hasVillageChange = typeof villageId !== 'undefined' && villageId !== selectedVillageId;
    const hasChange = hasCountryChange || hasSiteChange || hasRegionChange || hasVillageChange;

    // Re-generate the available options, and determine if values should be reset as a result.
    const selectSites = hasChange ? makeSites(countryId, allSitesByPermissions) : null;
    const needsSiteRemoval =
      siteId === selectedSiteId && !selectSites.map(l => l.id).includes(siteId);

    const selectRegions = hasChange
      ? makeRegions(
          countryId,
          hasSiteChange || hasRegionChange
            ? siteId
            : selectSites.length > 0
            ? selectSites.map(s => s.id)
            : null,
          allRegionsByPermissions
        )
      : null;
    const needsRegionRemoval =
      regionId === selectedRegionId && !selectRegions.map(l => l.id).includes(regionId);
    regionId = needsRegionRemoval ? null : regionId;

    const selectVillages = hasChange
      ? makeVillages(
          countryId,
          hasRegionChange || hasVillageChange
            ? regionId
            : selectRegions.length > 0
            ? selectRegions.map(r => r.id)
            : null,
          allVillagesByPermissions
        )
      : null;
    const needsVillageRemoval =
      villageId === selectedVillageId && !selectVillages.map(l => l.id).includes(villageId);
    villageId = needsVillageRemoval ? null : villageId;

    // Update selected values.
    if (hasCountryChange) {
      setSelectedCountryId(countryId);
    }
    if (hasSiteChange || needsSiteRemoval) {
      setSelectedSiteId(needsSiteRemoval || !siteId ? null : siteId);
    }
    if (hasRegionChange || needsRegionRemoval) {
      setSelectedRegionId(needsRegionRemoval || !regionId ? null : regionId);
    }
    if (hasVillageChange || needsVillageRemoval) {
      setSelectedVillageId(needsVillageRemoval || !villageId ? null : villageId);
    }

    // Update available options.
    if (hasChange) {
      setSelectSites(alphabetize(selectSites));
      setSelectRegions(alphabetize(selectRegions));
      setSelectVillages(alphabetize(selectVillages));
    }
  };

  const handleCountryChange = () => e => {
    const countryId = sanitize(e.target.value);
    if (countryId !== selectedCountryId) {
      updateLocation({
        countryId: countryId,
        siteId: selectedSiteId,
        regionId: selectedRegionId,
        villageId: selectedVillageId
      });
    }
  };

  const handleSiteChange = () => e => {
    const siteId = sanitize(e.target.value);
    const site = siteId ? allSitesByPermissions.find(s => s.id === siteId) : {};

    if (siteId !== selectedSiteId) {
      updateLocation({
        countryId: site ? site.countryId : selectedCountryId,
        siteId: siteId,
        regionId: selectedRegionId,
        villageId: selectedVillageId
      });
    }
  };

  const handleRegionChange = () => e => {
    const regionId = sanitize(e.target.value);
    const region = regionId ? allRegionsByPermissions.find(r => r.id === regionId) : {};

    if (regionId !== selectedRegionId) {
      updateLocation({
        countryId: region ? region.countryId : selectedCountryId,
        siteId: region && region.siteId ? region.siteId : selectedSiteId,
        regionId: regionId,
        villageId: selectedVillageId
      });
    }
  };

  const handleVillageChange = () => e => {
    const villageId = sanitize(e.target.value);
    const village = villageId ? allVillagesByPermissions.find(v => v.id === villageId) : {};
    const region =
      village && village.regionId
        ? allRegionsByPermissions.find(r => r.id === village.regionId)
        : null;

    if (villageId !== selectedVillageId) {
      updateLocation({
        countryId: village ? village.countryId : selectedCountryId,
        siteId: region && region.siteId ? region.siteId : selectedSiteId,
        regionId: village ? village.regionId : selectedRegionId,
        villageId: villageId
      });
    }
  };

  const handleChildNameChange = e => {
    setChildName(e.target.value);
  };

  const handleOutsideSystemNoChange = e => {
    setOutsideSystemNo(e.target.value);
  };

  const handleExcludeAssignedChildrenChange = e => {
    setExcludeAssignedChildren(e.target.checked);
  };

  const handleSupervisorChange = e => {
    setSelectedSupervisorId(e.target.value);
  };

  const handleAssignChildren = async selectedChildren => {
    const supervisorName = usersByRole.find(x => x.id === selectedSupervisorId).name;

    try {
      await assignSupervisor({
        variables: {
          childrenIds: selectedChildren.map(x => x.id),
          supervisorId: selectedSupervisorId
        }
      });
      ToasterContext.showSuccessToaster({
        message: `${selectedChildren.length} child(ren) assigned to ${supervisorName} successfully!`
      });
      fetchEntities();
    } catch (err) {
      ToasterContext.showErrorToaster({ message: err.message });
    }
  };

  const determineHasSearch = (newState: any) => {
    return (
      newState.selectedCountryId ||
      newState.selectedSiteId ||
      newState.selectedRegionId ||
      newState.selectedVillageId ||
      (newState.childName && newState.childName !== '') ||
      (newState.outsideSystemNo && newState.outsideSystemNo !== '')
    );
  };

  // Expose state to templating.
  const { classes } = props;
  const users = useMemo(
    () =>
      selectedCountryId
        ? usersByRole.filter(x => x.countries && x.countries.find(y => y.id === selectedCountryId))
        : usersByRole,
    [selectedCountryId, usersByRole]
  );

  return (
    <Grid container>
      <Paper className={classes.root} elevation={1}>
        <form>
          <Typography variant="h5" component="h3">
            Manage Children
          </Typography>
          <Grid container style={{ marginTop: 5 }}>
            <Grid item md={3}>
              <TextField
                select
                disabled={selectCountries.length <= 0}
                value={selectedCountryId || ''}
                onChange={handleCountryChange()}
                SelectProps={{ MenuProps: {} }}
                label="Country"
                margin="normal"
                className={classes.textField}
              >
                {selectCountries.map(x => (
                  <MenuItem key={x.id} value={x.id}>
                    {x.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item md={3}>
              <TextField
                select
                label="Site"
                disabled={selectSites.length <= 0}
                value={selectedSiteId || ''}
                onChange={handleSiteChange()}
                SelectProps={{ MenuProps: {} }}
                margin="normal"
                className={classes.textField}
              >
                {selectSites.map(x => (
                  <MenuItem key={x.id} value={x.id}>
                    {x.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item md={3}>
              <TextField
                select
                label="Region"
                disabled={selectRegions.length <= 0}
                value={selectedRegionId || ''}
                onChange={handleRegionChange()}
                SelectProps={{ MenuProps: {} }}
                margin="normal"
                className={classes.textField}
              >
                {selectRegions.map(x => (
                  <MenuItem key={x.id} value={x.id}>
                    {x.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item md={3}>
              <TextField
                select
                label="Village"
                helperText={selectVillages.length <= 0 ? 'Disabled' : null}
                disabled={selectVillages.length <= 0}
                value={selectedVillageId || ''}
                onChange={handleVillageChange()}
                SelectProps={{ MenuProps: {} }}
                margin="normal"
                className={classes.textField}
              >
                {selectVillages.map(x => (
                  <MenuItem key={x.id} value={x.id}>
                    {x.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item md={3}>
              <TextField
                name={'childName'}
                value={childName}
                label="Child Name"
                onChange={handleChildNameChange}
                margin="normal"
                className={classes.textField}
              ></TextField>
            </Grid>
            <Grid item md={3}>
              <TextField
                name={'outsideSystemNo'}
                value={outsideSystemNo}
                label="Child Number"
                onChange={handleOutsideSystemNoChange}
                margin="normal"
                className={classes.textField}
              ></TextField>
            </Grid>
            <Grid item md={3}>
              <label className={classes.checkboxField}>
                <Checkbox
                  name="showOnlyUnassignedChildren"
                  checked={excludeAssignedChildren || false}
                  onChange={handleExcludeAssignedChildrenChange}
                  value="excludeAssignedChildren"
                  color="primary"
                  className={classes.checkbox}
                />
                Show Only Unassigned Children
              </label>
            </Grid>
          </Grid>
          <ButtonToolbar
            submitButtonText="Search"
            handleCancel={handleClear}
            handleSubmit={handleSubmit}
          />
        </form>
        <Divider style={{ marginTop: 10, marginBottom: 10 }} />
        <Typography component="div">
          <div>
            <Typography style={{ width: '100%' }} component="div">
              <ChildrenTable
                options={{
                  exportButton: true,
                  emptyRowsWhenPaging: false,
                  exportCsv: (columns, data) => exportData(columns, entities)
                }}
                data={entities}
                hasSearch={hasSearch}
                handleChange={handleSupervisorChange}
                handleAssign={handleAssignChildren}
                selectedSupervisorId={selectedSupervisorId}
                usersByRole={users}
                loading={loading}
              />
            </Typography>
          </div>
        </Typography>
      </Paper>
    </Grid>
  );
};

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    width: '100%'
  },
  textField: {
    marginBottom: 20,
    marginLeft: 0,
    marginRight: 0,
    marginTop: 10,
    width: 'calc(100% - 30px)'
  },
  checkboxField: {
    display: 'block',
    fontSize: '14px',
    lineHeight: 1.1,
    marginBottom: 20,
    marginTop: 10,
    paddingBottom: 6,
    paddingLeft: 30,
    paddingTop: 20,
    width: 'calc(100% - 30px)'
  },
  checkbox: {
    marginLeft: -30,
    marginRight: 6,
    padding: 0
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  },
  formControl: {}
});

export default compose(withStyles(styles))(ManageChildrenPage);
