export const maybeUser = user => (user ? user.firstName + ' ' + user.lastName : 'None');

export const maybeDate = date => {
  if (date && !isNaN(+date)) {
    return new Date(+date).toLocaleString();
  } else if (date && isNaN(+date)) {
    return new Date(date).toLocaleString();
  } else {
    return 'None';
  }
};

export const maybeDateString = date => {
  if (date && !isNaN(+date)) {
    return new Date(+date).toLocaleString('en-US', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric'
    });
  } else if (date && isNaN(+date)) {
    return new Date(date).toLocaleString('en-US', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric'
    });
  } else {
    return '';
  }
};

export const maybeTimeString = date => {
  if (date && !isNaN(+date)) {
    return new Date(+date).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric' });
  } else if (date && isNaN(+date)) {
    return new Date(date).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric' });
  } else {
    return '';
  }
};
