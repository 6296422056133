import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withRouter, useRouteMatch, useHistory } from 'react-router-dom';
import { flowRight as compose } from 'lodash';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import classnames from 'classnames';
import CardContent from '@material-ui/core/CardContent';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ChildQuery from '../../../graphql/Child/child-query';
import UpdateStatusMutation from '../../../graphql/Child/update-child-status';
import CommonTable from '../../common/CommonTable';
import { Link } from 'react-router-dom';
import { maybeDate } from '../../../util/maybe';
import { useQuery, useMutation } from '@apollo/client';
import { dateSort } from 'util/sort-util';
import { Grid, TextField, MenuItem, FormHelperText } from '@material-ui/core';
import { exportCsv } from 'util/export-util';

const CHILD_ALERTS_COLUMNS = [
  { title: 'Child Alert Message', field: 'message' },
  {
    title: 'Created On Date',
    field: 'createdOnDate',
    render: rowData => {
      return rowData.createdOnDate ? (
        <span style={{ whiteSpace: 'nowrap' }}>{maybeDate(rowData.createdOnDate)}</span>
      ) : (
        <span style={{ color: '#767676' }}>None</span>
      );
    }
  },
  {
    title: 'Submitted On Date',
    field: 'submittedOnDate',
    render: rowData => {
      return rowData.submittedOnDate ? (
        <span style={{ whiteSpace: 'nowrap' }}>{maybeDate(rowData.submittedOnDate)}</span>
      ) : (
        <span style={{ color: '#767676' }}>None</span>
      );
    }
  },
  {
    title: 'Acknowledged',
    field: 'isAcknowledged',
    render: rowData => <span>{rowData.isAcknowledged ? 'Yes' : 'No'}</span>
  }
];

const SPONSOR_MESSAGES_COLUMNS = [
  { title: 'Message', field: 'message' },
  {
    title: 'Created On Date',
    field: 'createdOnDate',
    render: rowData => {
      return rowData.createdOnDate ? (
        <span style={{ whiteSpace: 'nowrap' }}>{maybeDate(rowData.createdOnDate)}</span>
      ) : (
        <span style={{ color: '#767676' }}>None</span>
      );
    }
  }
];

const CHILD_MESSAGES_COLUMNS = [
  { title: 'Message', field: 'message' },
  {
    title: 'Created On Date',
    field: 'createdOnDate',
    render: rowData => {
      return rowData.createdOnDate ? (
        <span style={{ whiteSpace: 'nowrap' }}>{maybeDate(rowData.createdOnDate)}</span>
      ) : (
        <span style={{ color: '#767676' }}>None</span>
      );
    }
  },
  {
    title: 'Submitted On Date',
    field: 'submittedOnDate',
    render: rowData => {
      return rowData.submittedOnDate ? (
        <span style={{ whiteSpace: 'nowrap' }}>{maybeDate(rowData.submittedOnDate)}</span>
      ) : (
        <span style={{ color: '#767676' }}>None</span>
      );
    }
  }
];

const SURVEY_COMPLETEDS_COLUMNS = [
  {
    title: 'Survey',
    field: 'name',
    render: rowData => (
      <Link to={`/survey/${rowData.id}/${rowData.surveyTemplateId}`}>Go to Survey</Link>
    )
  },
  {
    title: 'Created On Date',
    field: 'createdOnDate',
    render: rowData => {
      return rowData.createdOnDate ? (
        <span style={{ whiteSpace: 'nowrap' }}>{maybeDate(rowData.createdOnDate)}</span>
      ) : (
        <span style={{ color: '#767676' }}>None</span>
      );
    }
  },
  {
    title: 'Submitted On Date',
    field: 'submittedOnDate',
    render: rowData => {
      return rowData.submittedOnDate ? (
        <span style={{ whiteSpace: 'nowrap' }}>{maybeDate(rowData.submittedOnDate)}</span>
      ) : (
        <span style={{ color: '#767676' }}>None</span>
      );
    }
  }
];

const CHECKUPS_COLUMNS = [
  { title: 'Child Alert Message', field: 'message1' },
  { title: 'Needs / Observations', field: 'message2' },
  {
    title: 'Created On Date',
    field: 'createdOnDate',
    render: rowData => {
      return rowData.createdOnDate ? (
        <span style={{ whiteSpace: 'nowrap' }}>{maybeDate(rowData.createdOnDate)}</span>
      ) : (
        <span style={{ color: '#767676' }}>None</span>
      );
    }
  },
  {
    title: 'Submitted On Date',
    field: 'submittedOnDate',
    render: rowData => {
      return rowData.submittedOnDate ? (
        <span style={{ whiteSpace: 'nowrap' }}>{maybeDate(rowData.submittedOnDate)}</span>
      ) : (
        <span style={{ color: '#767676' }}>None</span>
      );
    }
  }
];

export const getName = user => {
  if (!user) return '';
  return user.firstName + ' ' + user.lastName;
};

const exportData = (columns, data, type) => {
  let jsonData = [];
  let filename = null;
  if (type === 'checkups') {
    filename = 'gfdata-export-checkups.csv';
    jsonData = data.map(x => ({
      'Created on Date': maybeDate(x.createdOnDate),
      'Child Alert Message': x.message1,
      'Needs and Observations': x.message2,
      'Care Worker': getName(x.user)
    }));
  } else if (type === 'surveys') {
    filename = 'gfdata-export-surveys.csv';
    jsonData = data.map(x => ({
      'Created on Date': maybeDate(x.createdOnDate),
      'Care Worker': getName(x.user)
    }));
  } else if (type === 'childAlerts') {
    filename = 'gfdata-export-childAlerts.csv';
    jsonData = data.map(x => ({
      'Created on Date': maybeDate(x.createdOnDate),
      'Child Alert Message': x.message,
      Acknowledged: x.isAcknowledged
    }));
  } else if (type === 'childMessages') {
    filename = 'gfdata-export-childMessages.csv';
    jsonData = data.map(x => ({
      'Created on Date': maybeDate(x.createdOnDate),
      'Submitted on Date': maybeDate(x.submittedOnDate),
      'Child Message': x.message
    }));
  } else if (type === 'sponsorMessages') {
    filename = 'gfdata-export-sponsorMessages.csv';
    jsonData = data.map(x => ({
      'Created on Date': maybeDate(x.createdOnDate),
      'Sponsor Message': x.message
    }));
  }

  exportCsv(jsonData, filename);
};

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    width: '100%'
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: 'rotate(180deg)'
  },
  card: {},
  bigAvatar: {
    marginBottom: 16,
    marginRight: 16,
    width: 175,
    height: 175
  },
  heading: {
    // fontSize: theme.typography.pxToRem(15),
    // fontWeight: theme.typography.fontWeightRegular,
  }
});

const getLastMessageDate = messages => {
  if (!messages || !messages.length) return 'None';

  const sortedMessages = messages.slice().sort(dateSort('submittedOnDate'));

  return sortedMessages
    ? maybeDate(sortedMessages[sortedMessages.length - 1].submittedOnDate)
    : 'None';
};

const ChildPage = props => {
  const [state, setState] = useState({ expanded: false });
  const match = useRouteMatch<any>();
  const { data }: any = useQuery(ChildQuery, {
    variables: { id: +match.params.id },
    fetchPolicy: 'network-only'
  });
  const [updateStatusMutation] = useMutation(UpdateStatusMutation);
  const history = useHistory();

  const handleExpandClick = () => {
    setState(state => ({ expanded: !state.expanded }));
  };

  const handleStatusChange = async e => {
    await updateStatusMutation({ variables: { id: +match.params.id, status: e.target.value } });

    history.goBack();
  };

  const render = () => {
    const { classes } = props;
    const {
      avatar,
      name,
      outsideSystemNo,
      child_imgs: childImgs = [],
      village = {},
      country = {},
      user = {},
      lastCheckupDate,
      lastSurveyDate
    } = data?.child || {};
    const fullName = user && user.firstName ? user.firstName + ' ' + user.lastName : 'None';
    const completedSurveys = data?.child?.survey_completeds
      ? data.child.survey_completeds.map(x => ({ ...x }))
      : [];
    const completedCheckups = data?.child?.checkups ? data.child.checkups.map(x => ({ ...x })) : [];
    const childAlerts = data?.child?.child_alerts
      ? data.child.child_alerts.map(x => ({ ...x }))
      : [];
    const childMessages = data?.child?.child_messages
      ? data.child.child_messages.map(x => ({ ...x }))
      : [];
    const sponsorMessages = data?.child?.sponsor_messages
      ? data.child.sponsor_messages.map(x => ({ ...x }))
      : [];

    return (
      <React.Fragment>
        <Card className={classes.card}>
          <CardHeader
            style={{ alignItems: 'flex-start' }}
            avatar={
              <Avatar
                src={avatar}
                alt={fullName}
                aria-label="Recipe"
                className={classes.bigAvatar}
              ></Avatar>
            }
            title={
              <Typography variant="h5" component="h3">
                {name}
              </Typography>
            }
            subheader={
              <div>
                <Typography
                  component="div"
                  style={{ color: 'gray' }}
                  variant="subtitle1"
                  gutterBottom
                >
                  <div>
                    {village.name && country.name
                      ? village.name + ', ' + country.name
                      : village.name || country.name
                      ? village.name + '' + country.name
                      : ''}
                  </div>
                </Typography>
                <Typography
                  component="div"
                  style={{ color: 'gray' }}
                  variant="subtitle1"
                  gutterBottom
                >
                  <div>
                    Care Worker:&nbsp;
                    {fullName}
                  </div>
                  <div>
                    Child No:&nbsp;
                    {outsideSystemNo || '...'}
                  </div>
                </Typography>
                <Typography component="div" variant="body1" style={{ fontSize: 13, color: 'gray' }}>
                  <div>Last Survey: {maybeDate(lastSurveyDate)}</div>
                  <div>Last Checkup: {maybeDate(lastCheckupDate)}</div>
                  <div>Last Child Message: {getLastMessageDate(childMessages)}</div>
                </Typography>
                <Grid item md={3}>
                  <TextField
                    style={{ width: '80%' }}
                    select
                    name="status"
                    value={'active'}
                    onChange={handleStatusChange}
                    SelectProps={{
                      MenuProps: {}
                    }}
                    margin="normal"
                    className={classes.textField}
                  >
                    <MenuItem key={'active'} value={'active'}>
                      Active
                    </MenuItem>
                    <MenuItem key={'inactive'} value={'inactive'}>
                      Inactive
                    </MenuItem>
                  </TextField>
                  <FormHelperText>Status</FormHelperText>
                </Grid>
              </div>
            }
          />
          <CardActions className={classes.actions}>
            <IconButton
              className={classnames(classes.expand, {
                [classes.expandOpen]: state.expanded
              })}
              onClick={handleExpandClick}
              aria-expanded={state.expanded}
              aria-label="Show more"
            >
              <ExpandMoreIcon />
            </IconButton>
          </CardActions>
          <Collapse in={state.expanded} timeout="auto" unmountOnExit>
            <CardContent>
              <Typography component="h2" style={{ fontWeight: 700, marginBottom: 20 }}>
                Child Images
              </Typography>
              <Typography component="div">
                {childImgs.map(img => (
                  <div
                    key={img.id}
                    style={{ alignItems: 'center', display: 'flex', marginBottom: 20 }}
                  >
                    <div
                      style={{
                        width: 175,
                        height: 175,
                        marginRight: 20,
                        overflow: 'hidden',
                        position: 'relative'
                      }}
                    >
                      <img
                        src={img.imgUrl}
                        alt={img.title}
                        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                      />
                      {img.isApproved ? (
                        <CheckCircleIcon
                          style={{
                            backgroundColor: 'white',
                            borderRadius: 15,
                            color: 'green',
                            width: 30,
                            height: 30,
                            position: 'absolute',
                            bottom: 10,
                            right: 10,
                            zIndex: 10
                          }}
                        />
                      ) : null}
                    </div>
                    <div>
                      <div>Approved: {img.isApproved ? 'Yes' : 'No'}</div>
                      <div>Source: {img.isFromMobile ? 'GFKidsApp' : 'GF Admin'}</div>
                      <div>Created: {maybeDate(img.createdOnDate)}</div>
                      <div style={{ color: 'gray' }}>Image ID: {img.id}</div>
                    </div>
                  </div>
                ))}
              </Typography>
            </CardContent>
          </Collapse>
        </Card>

        <ExpansionPanel style={{ marginTop: 30 }}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h5" component="h3" className={classes.heading}>
              Checkups
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography component="div" style={{ width: '100%' }}>
              <CommonTable
                options={{
                  search: false,
                  exportButton: true,
                  exportCsv: (columns, data) => exportData(columns, completedCheckups, 'checkups')
                }}
                columns={CHECKUPS_COLUMNS}
                data={completedCheckups}
              />
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel style={{ marginTop: 30 }}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h5" component="h3" className={classes.heading}>
              Surveys
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography component="div" style={{ width: '100%' }}>
              <CommonTable
                options={{
                  search: false,
                  exportButton: true,
                  exportCsv: (columns, data) => exportData(columns, completedSurveys, 'surveys')
                }}
                columns={SURVEY_COMPLETEDS_COLUMNS}
                data={completedSurveys}
              />
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel style={{ marginTop: 30 }}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h5" component="h3" className={classes.heading}>
              Child Alerts
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography component="div" style={{ width: '100%' }}>
              <CommonTable
                options={{
                  search: false,
                  exportButton: true,
                  exportCsv: (columns, data) => exportData(columns, childAlerts, 'childAlerts')
                }}
                columns={CHILD_ALERTS_COLUMNS}
                data={childAlerts}
              />
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel style={{ marginTop: 30 }}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h5" component="h3" className={classes.heading}>
              Child Messages
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography component="div" style={{ width: '100%' }}>
              <CommonTable
                options={{
                  search: false,
                  exportButton: true,
                  exportCsv: (columns, data) => exportData(columns, childMessages, 'childMessages')
                }}
                columns={CHILD_MESSAGES_COLUMNS}
                data={childMessages}
              />
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel style={{ marginTop: 30 }}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h5" component="h3" className={classes.heading}>
              Sponsor Messages
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography component="div" style={{ width: '100%' }}>
              <CommonTable
                options={{
                  search: false,
                  exportButton: true,
                  exportCsv: (columns, data) =>
                    exportData(columns, sponsorMessages, 'sponsorMessages')
                }}
                columns={SPONSOR_MESSAGES_COLUMNS}
                data={sponsorMessages}
              />
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </React.Fragment>
    );
  };

  return render();
};

export default compose(withStyles(styles), withRouter)(ChildPage);
