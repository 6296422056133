import { gql } from '@apollo/client'
import { BaseSurveyTemplateEntityFragment } from '../Fragments/survey-template-entity';

export default gql`
  query($id: Int!, $surveyTemplateId: Int!) {
    surveyCompleted(id: $id) {
      id
      surveyTemplateId
      survey_answers {
        domainId
        categoryId
        optionId
        childAlertId
        message1
        message2
        scoreValue
      }
    }
    childAlertsBySurveyCompletedId(surveyCompletedId: $id) {
      id
      surveyCompletedId
      domainId
      message
    }
    surveyTemplate(surveyTemplateId: $surveyTemplateId) {
      ...BaseSurveyTemplate
    }
  }
  ${BaseSurveyTemplateEntityFragment}
`;
