import React from 'react';
import _ from 'lodash';
import { maybeDate } from '../../util/maybe';
import { sort } from '../../util/sort-util';
import Button from '@material-ui/core/Button';
import MaterialTable, { MTableToolbar } from 'material-table';
import { Link } from 'react-router-dom';
import { tableIcons } from './table-icons';

const CHILD_ALERT_COLUMNS = [
  { title: 'Child Alert Message', sorting: false, field: 'message' },
  {
    title: 'Child Name',
    customSort: sort('child.name'),
    render: rowData => <Link to={`/child/${rowData.child.id}`}>{rowData.child.name}</Link>
  },
  {
    title: 'Care Worker',
    username: sort('child.user.firstName'),
    render: rowData => (
      <span>
        {_.get(rowData, 'child.user.firstName', '') +
          ' ' +
          _.get(rowData, 'child.user.lastName', '')}
      </span>
    )
  },
  {
    title: 'Country Name',
    customSort: sort('child.country.name'),
    render: rowData => <span>{_.get(rowData, 'child.country.name', '')}</span>
  },
  {
    title: 'Village Name',
    customSort: sort('child.village.name'),
    render: rowData => <span>{_.get(rowData, 'child.village.name', '')}</span>
  },
  {
    title: 'Created On Date',
    field: 'createdOnDate',
    render: rowData => <span>{maybeDate(rowData.createdOnDate)}</span>
  },
  {
    title: 'Acknowledged',
    sorting: false,
    field: 'isAcknowledged',
    render: rowData => <span>{rowData.isAcknowledged ? 'Yes' : 'No'}</span>
  }
];

const ChildAlertTable = ({ handleAcknowledge, data = [], options, ...restProps }) => {
  const handleAcknowledgeChildAlerts = props => () => {
    handleAcknowledge(props.selectedRows);
  };

  return (
    <MaterialTable
      icons={tableIcons}
      title=""
      columns={CHILD_ALERT_COLUMNS}
      data={data}
      options={{
        selection: data.length ? true : false,
        search: false,
        emptyRowsWhenPaging: false,
        pageSize: 10,
        pageSizeOptions: [10, 50],
        ...options
      }}
      components={{
        Toolbar: props => (
          <React.Fragment>
            {data.length ? <MTableToolbar {...props} /> : null}
            {props.selectedRows.length ? (
              <Button
                style={{ marginLeft: 15, marginTop: 15 }}
                variant="contained"
                onClick={handleAcknowledgeChildAlerts(props)}
                color="secondary"
              >
                Acknowledge
              </Button>
            ) : null}
          </React.Fragment>
        )
      }}
      {...restProps}
    />
  );
};

export default ChildAlertTable;
