import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import FormHelperText from '@material-ui/core/FormHelperText';

class AddLanguageModal extends Component<any, any> {
  state = {} as any;

  reset = () => {
    this.setState({ language: null, warning: false, err: false });
  };

  handleCancel = () => {
    this.props.onCancel();
    this.reset();
  };

  handleSubmit = () => {
    const { language } = this.state;
    this.props.onSubmit({ language });
    this.reset();
  };

  handleChange = e => {
    const { languages = [] } = this.props;
    const warningLanguage = languages.find(({ name }) =>
      name.toLowerCase().includes(e.target.value.toLowerCase())
    );
    const err = languages.find(
      ({ name }) => name.toLowerCase() === e.target.value.toLowerCase()
    ) ? (
      <span>
        Error: You already have a created language named <b>{e.target.value}</b>
      </span>
    ) : (
      false
    );
    const warning =
      e.target.value && e.target.value.length > 3 && warningLanguage ? (
        <span>
          Warning: You already have a created language similarly named to{' '}
          <b>
            {e.target.value} ({warningLanguage.name})
          </b>
        </span>
      ) : (
        false
      );

    this.setState({ [e.target.name]: e.target.value, warning, err });
  };

  render = () => {
    const { open = false, classes } = this.props;
    const { language, err, warning } = this.state;

    return (
      <Dialog open={open} onClose={this.handleCancel} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Add Language</DialogTitle>
        <DialogContent>
          <div>
            <TextField
              id="language-name"
              label="Language"
              name="language"
              className={classes.textField}
              value={language}
              onChange={this.handleChange}
              margin="normal"
            />
          </div>
          <div style={{ marginTop: 20 }}>
            <FormHelperText style={{ color: 'red' }}>{err}</FormHelperText>
            {!err && <FormHelperText style={{ color: 'orange' }}>{warning}</FormHelperText>}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleCancel} color="primary">
            Cancel
          </Button>
          <Button disabled={!!err || !language} onClick={this.handleSubmit} color="secondary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    );
  };
}

const styles = theme => ({
  textField: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
    width: 400
  }
});

export default withStyles(styles)(AddLanguageModal);
