import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';

const ITEM_HEIGHT = 48;

class MoreVertMenu extends React.Component<any, any> {
  state = {
    anchorEl: null
  };

  handleClick = e => {
    this.setState({ anchorEl: e.currentTarget });
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();

    this.props.handleOpen(e);
  };

  handleClose = ({ name, title, value }) => e => {
    this.setState({ anchorEl: null });
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();

    this.props.handleMenuItemClick && this.props.handleMenuItemClick({ name, title, value });
  };

  render() {
    const { anchorEl } = this.state;
    const { items = [], open } = this.props;

    return (
      <React.Fragment>
        <IconButton
          aria-label="More"
          aria-owns={open ? 'long-menu' : undefined}
          aria-haspopup="true"
          onClick={this.handleClick}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="long-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={this.handleClose}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
              width: 200
            }
          }}
        >
          {items.map(({ name, title, value }) => (
            <MenuItem
              key={name}
              selected={false}
              onClick={this.handleClose({ name, title, value })}
            >
              {title}
            </MenuItem>
          ))}
        </Menu>
      </React.Fragment>
    );
  }
}

export default MoreVertMenu;
