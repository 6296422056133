import './App.css';
import { client } from 'graphql/client';
import React, { Component } from 'react';
import { Route, Switch } from 'react-router';
import { ApolloProvider } from '@apollo/client';
import { BrowserRouter } from 'react-router-dom';
import JssProvider from 'react-jss/lib/JssProvider';
import ToasterProvider from './state/providers/toaster';
import LoginPage from 'components/modules/Login/LoginPage';
import AuthorizedRoute from 'components/auth/AuthorizedRoute';
import { MuiThemeProvider, createMuiTheme, createGenerateClassName } from '@material-ui/core';

const generateClassName = createGenerateClassName({
  seed: 'globalfingerprintsdata'
});

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#142952',
      light: '#142952'
    },
    secondary: {
      main: '#008080',
      light: '#008080'
    }
  }
});

const appStyles = {
  backgroundColor: '#f5f7fa',
  height: '100vh'
};
class App extends Component {
  render() {
    return (
      <div style={appStyles}>
        <ApolloProvider client={client}>
          <BrowserRouter>
            <JssProvider generateClassName={generateClassName}>
              <MuiThemeProvider theme={theme}>
                <ToasterProvider>
                  <Switch>
                    <Route exact path="/login" component={LoginPage} />
                    <Route path="/" component={AuthorizedRoute} />
                  </Switch>
                </ToasterProvider>
              </MuiThemeProvider>
            </JssProvider>
          </BrowserRouter>
        </ApolloProvider>
      </div>
    );
  }
}

export default App;
