import { gql } from '@apollo/client'

export const BaseSupervisorFragment = gql`
  fragment Supervisor on User {
    id
    firstName
    lastName
    supervisorName
    companyId
  }
`;
