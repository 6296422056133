import { gql } from '@apollo/client';
import { BaseSupervisorFragment } from './supervisor-fragment';

export const BaseChildFragment = gql`
  fragment BaseChild on Child {
    id
    companyId
    name
    lastSponsorMessageDate
    lastSurveyDate
    lastCheckupDate
    lastSurveyJson
    lastCheckupJson
    outsideSystemNo
    countryId
    villageId
    country {
      id
      name
    }
    village {
      id
      name
    }
    user {
      ...Supervisor
    }
    lastMessageDate
    lastImageDate
    status
  }
  ${BaseSupervisorFragment}
`;
