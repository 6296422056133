import React from 'react';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  }
});
export class CheckupPage extends React.Component<any, any> {
  render() {
    const { classes } = this.props;
    return (
      <Paper className={classes.root} elevation={1}>
        <Typography variant="h5" component="h3">
          Checkup
        </Typography>
        <List component="nav">
          <ListItem button>
            <ListItemIcon>
              <span>Child Alert</span>
            </ListItemIcon>
            <ListItemText primary="Inbox" />
          </ListItem>
          <ListItem button>
            <ListItemIcon>
              <span>Needs and Observations</span>
            </ListItemIcon>
            <ListItemText primary="Drafts" />
          </ListItem>
          <ListItem button>
            <ListItemIcon>
              <span>Child Message</span>
            </ListItemIcon>
            <ListItemText primary="lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum/lorem ipsum lorem ipsum>lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum/lorem ipsum lorem ipsum>lorem ipsum lorem ipsum" />
          </ListItem>
        </List>
      </Paper>
    );
  }
}

export default withStyles(styles)(CheckupPage);
