import { gql } from '@apollo/client';
import { BaseChildFragment } from '../Fragments/child-fragment';

export default gql`
  query($countryId: Int) {
    childrenWithMessageViolations(countryId: $countryId) {
      ...BaseChild
    }
  }
  ${BaseChildFragment}
`;
