import { gql } from '@apollo/client';
import { BaseSurveyTemplateEntityFragment } from '../Fragments/survey-template-entity';

export default gql`
  query($role: RoleEnum, $surveyTemplateId: Int) {
    surveyTemplates {
      id
      name
      userIdCreatedBy
      isDefault
      createdOnDate
    }
    surveyTemplate(surveyTemplateId: $surveyTemplateId) {
      ...BaseSurveyTemplate
    }
    allCountriesByPermissions {
      id
      name
    }
    allSitesByPermissions {
      id
      countryId
      name
    }
    allRegionsByPermissions {
      id
      countryId
      siteId
      name
    }
    allVillagesByPermissions {
      id
      countryId
      regionId
      name
    }
    usersByRole(role: $role) {
      id
      name
      countries {
        id
      }
    }
  }
  ${BaseSurveyTemplateEntityFragment}
`;
