import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Avatar from '@material-ui/core/Avatar';
import logo from '../../assets/logo.png';
import Config from '../../config';
import { useHistory } from 'react-router';
import useUserStore from 'state/hooks/use-user-store';

const drawerWidth = 240;

const styles = theme => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    ...(Config.env !== 'prod' ? { backgroundColor: 'black' } : {})
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36
  },
  hide: {
    display: 'none'
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(2)
  },
  flexGrow: {
    flexGrow: 1
  }
});

const NavAppBar = props => {
  const [state, setState] = useState({ peopleIconMenu: null });
  const [{ me }, { logout }] = useUserStore();
  const history = useHistory();

  const handlePeopleIconMenuClick = e => {
    setState({ ...state, peopleIconMenu: e.target });
  };

  const handleClose = name => () => {
    if (name === 'settings') {
      history.push('/settings');
    } else if (name === 'logout') {
      logout();
      history.push('/login');
    }

    setState({ ...state, peopleIconMenu: null });
  };

  const render = () => {
    const { classes, open, handleDrawerOpen } = props;
    const { peopleIconMenu } = state;
    const name = me ? me.firstName + ' ' + me.lastName : '';

    return (
      <AppBar
        position="fixed"
        className={classNames(classes.appBar, {
          [classes.appBarShift]: open
        })}
      >
        <Toolbar disableGutters={!open}>
          <IconButton
            color="inherit"
            aria-label="Open drawer"
            onClick={handleDrawerOpen}
            className={classNames(classes.menuButton, {
              [classes.hide]: open
            })}
          >
            {/* <MenuIcon /> */}
            <Avatar src={logo} />
          </IconButton>
          <Typography variant="h6" color="inherit" noWrap className={classes.flexGrow}>
            Globalfingerprints Data {Config.env !== 'prod' ? `(${Config.env})` : ''}
          </Typography>
          <span style={{ marginRight: 30, color: 'white' }}>
            <Typography color="inherit">
              <span style={{ fontSize: 14 }}>{name}</span>
              <IconButton
                aria-owns={peopleIconMenu ? 'simple-menu' : undefined}
                aria-haspopup="true"
                onClick={handlePeopleIconMenuClick}
              >
                <PersonOutlineIcon style={{ color: 'white' }} />
              </IconButton>
            </Typography>
          </span>
          <Menu
            id="simple-menu"
            anchorEl={peopleIconMenu}
            open={!!peopleIconMenu}
            onClose={handleClose(null)}
          >
            <MenuItem onClick={handleClose('settings')}>Settings</MenuItem>
            <MenuItem onClick={handleClose('logout')}>Logout</MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
    );
  };

  return render();
};

export default withStyles(styles, { withTheme: true })(NavAppBar);
