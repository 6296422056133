import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import CommonTable from '../../common/CommonTable';
import { flowRight as compose } from 'lodash';
import FIND_USERS_BY_PERMISSIONS_QUERY from '../../../graphql/Users/find-users-by-permissions-query';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import AddOrEditUserModal from '../../modals/AddOrEditUserModal';
import DeletePromptModal from '../../modals/DeletePromptModal';
import { validate } from '../../../util/form-validator';
import CREATE_USER_MUTATION from '../../../graphql/Users/create-mutation';
import UPDATE_USER_MUTATION from '../../../graphql/Users/update-mutation';
import DELETE_USER_MUTATION from '../../../graphql/Users/delete-mutation';
import findUserByIdQuery from '../../../graphql/Users/find-by-id-query';
import cloneDeep from 'lodash/cloneDeep';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/Edit';
import useUserStore from 'state/hooks/use-user-store';
import { useApolloClient, useQuery, useMutation } from '@apollo/client';
import useToaster from 'state/hooks/use-toaster';
import { sort } from 'util/sort-util';

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    width: '80%'
  },
  fab: {
    marginLeft: 10
  }
});

const isPasswordValid = (p1 = '', p2 = '') => {
  if (!p1 && !p2) return true;
  return p1 && p2 && p1 === p2 && p1.length >= 5;
};

const PASSWORD_FORM_CONTROLS = {
  password1: {
    test: (value, form) => isPasswordValid(value, form.password2),
    err: false,
    message: 'Passwords must match and be at least 5 characters'
  },
  password2: {
    test: (value, form) => isPasswordValid(value, form.password1),
    err: false,
    message: 'Passwords must match and be at least 5 characters'
  }
};
const FORM_CONTROLS = {
  firstName: { test: value => value, err: false, message: 'Required' },
  lastName: { test: value => value, err: false, message: 'Required' },
  username: { test: value => value, err: false, message: 'Required' },
  role: { test: value => value, err: false, message: 'Required' },
  countries: {
    test: (value, input) => (input.role === 'CAREWORKER' ? value : value && value.length),
    err: false,
    message: 'Required'
  }
};

const USER_COLUMNS = [
  {
    title: 'Name',
    field: 'name',
    customSort: sort('firstName'),
    render: rowData => (
      <div>
        <strong>{rowData.firstName + ' ' + rowData.lastName}</strong>
        <br />
        <span style={{ color: '#767676' }}>{rowData.username}</span>
      </div>
    )
  },
  {
    title: 'Role',
    field: 'role',
    render: rowData => <span>{rowData.role}</span>,
    customSort: sort('role')
  },
  {
    title: 'Countries',
    sorting: false,
    render: rowData => (
      <div style={{ color: '#767676', maxWidth: '50ch' }}>
        {rowData.countries.length ? rowData.countries.map(x => x.name).join(', ') : 'None'}
      </div>
    )
  }
];

const UsersPage = props => {
  const [, ToasterContext] = useToaster();
  const [{ me }] = useUserStore();
  const [state, setState] = useState({
    isAddOrEditUserModalOpen: false,
    isDeletePromptModalOpen: false,
    deleteUserId: null,
    isAddOrEditUserModalValid: false,
    type: null,
    countriesDropdownOptions: me.countries.map(x => ({ id: x.id, name: x.name })),
    rolesDropdownOptions:
      me.role === 'SUPERADMIN' ? ['SUPERADMIN', 'ADMIN', 'CAREWORKER'] : ['CAREWORKER'],
    formControls: cloneDeep({ ...FORM_CONTROLS, ...PASSWORD_FORM_CONTROLS })
  } as any);
  const client = useApolloClient();
  const {
    data: { usersByPermissions = [] } = {},
    refetch: refetchUsers
  } = useQuery(FIND_USERS_BY_PERMISSIONS_QUERY, { fetchPolicy: 'network-only' });
  const [createUser] = useMutation(CREATE_USER_MUTATION);
  const [updateUser] = useMutation(UPDATE_USER_MUTATION);
  const [deleteUser] = useMutation(DELETE_USER_MUTATION);
  const users = usersByPermissions.map(x => ({ ...x }));

  const handleSubmit = async ({ type }) => {
    const {
      notesFromCreator,
      firstName,
      lastName,
      password1,
      password2,
      email,
      username,
      role,
      countries,
      id
    } = state;
    const formControls = {
      ...cloneDeep({ ...FORM_CONTROLS, ...(state.passwordFieldsVisible && PASSWORD_FORM_CONTROLS) })
    };
    const errors = validate(
      { firstName, lastName, password1, password2, email, username, role, countries },
      formControls
    );

    if (errors) {
      setState({ ...state, isAddOrEditUserModalValid: false, formControls: cloneDeep(errors) });
      return;
    } else {
      setState({ ...state, isAddOrEditUserModalValid: true });
    }

    try {
      /**
       * NOTE: the reason for the code `Array.isArray(countries) ? countries : [countries]` is bc
       * Care Workers can only have 1 country, so the form select dropdown will return a primitive if changed;
       * if not changed, the array from the server is kept in tact. thus the need for conditional here
       */
      if (type === 'add') {
        await createUser({
          variables: {
            input: {
              notesFromCreator,
              firstName,
              lastName,
              password1,
              password2,
              email,
              username,
              role,
              countries: Array.isArray(countries) ? countries : [countries]
            }
          }
        });
        ToasterContext.showSuccessToaster({ message: 'User created successfully!' });
      } else if (type === 'edit') {
        await updateUser({
          variables: {
            input: {
              notesFromCreator,
              firstName,
              lastName,
              password1,
              password2,
              role,
              email,
              username,
              id,
              countries: Array.isArray(countries) ? countries : [countries]
            }
          }
        });
        ToasterContext.showSuccessToaster({ message: 'User edited successfully!' });
      }
      refetchUsers();
    } catch (err) {
      return ToasterContext.showErrorToaster({
        message: err.message || 'Uh oh! Error editing user.'
      });
    }

    handleCloseAddOrEditUserModal();
  };

  const handleOpenAddUserModal = () => {
    setState({
      ...state,
      isAddOrEditUserModalOpen: true,
      type: 'add',
      passwordFieldsVisible: true,
      formControls: cloneDeep({ ...FORM_CONTROLS, ...PASSWORD_FORM_CONTROLS })
    });
  };

  const handleCloseAddOrEditUserModal = () => {
    setState({
      ...state,
      isAddOrEditUserModalValid: false,
      isAddOrEditUserModalOpen: false,
      type: null,

      // user
      id: null, // for edit-user
      notesFromCreator: null,
      firstName: null,
      lastName: null,
      password1: null,
      password2: null,
      email: null,
      username: null,
      role: [],
      countries: [],
      formControls: cloneDeep({ ...FORM_CONTROLS, ...PASSWORD_FORM_CONTROLS })
    });
  };

  const handleShowDeletePromptModal = async (event, rowData) => {
    setState({ ...state, isDeletePromptModalOpen: true, deleteUserId: rowData.id });
  };

  const handleCloseDeletePromptModal = async () => {
    setState({ ...state, isDeletePromptModalOpen: false, deleteUserId: null });
  };

  const handleDeleteUser = async () => {
    const { deleteUserId } = state;

    try {
      await deleteUser({ variables: { id: deleteUserId } });
      ToasterContext.showSuccessToaster({ message: 'User deleted successfully!' });
      refetchUsers();
    } catch (err) {
      ToasterContext.showErrorToaster({ message: err.message });
    }

    handleCloseDeletePromptModal();
  };

  const handleUpdateUser = async (event, rowData) => {
    // todo refactor to useLazyQuery
    const {
      data: { user }
    } = await client.query({
      query: findUserByIdQuery,
      variables: { id: rowData.id },
      fetchPolicy: 'network-only'
    });
    const passwordFieldsVisible = me.role === 'SUPERADMIN';

    setState({
      ...state,
      isAddOrEditUserModalOpen: true,
      type: 'edit',
      passwordFieldsVisible,
      formControls: cloneDeep({
        ...FORM_CONTROLS,
        ...(passwordFieldsVisible && PASSWORD_FORM_CONTROLS)
      }),
      ...user,
      countries: user.countries.map(x => x.id)
    });
  };

  const handleChange = async e => {
    const conditionalProps = {} as any;

    // reset 'countries' state if 'role' changed
    // 'CAREWORKER' role only allowed 1 country
    if (e.target.name === 'role') {
      if (e.target.value === 'CAREWORKER') conditionalProps.countries = null;
      else conditionalProps.countries = [];
    }
    setState({
      ...state,
      [e.target.name]: e.target.value,
      ...conditionalProps
    });
  };

  const render = () => {
    const { classes } = props;
    const {
      isAddOrEditUserModalOpen,
      isAddOrEditUserModalValid,
      isDeletePromptModalOpen,
      type,
      countriesDropdownOptions,
      rolesDropdownOptions,
      formControls,
      notesFromCreator,
      firstName,
      lastName,
      password1,
      password2,
      email,
      username,
      role,
      countries
    } = state;

    return (
      <React.Fragment>
        <AddOrEditUserModal
          passwordFieldsVisible={state.passwordFieldsVisible}
          open={isAddOrEditUserModalOpen}
          valid={isAddOrEditUserModalValid}
          formControls={formControls}
          type={type}
          title={type === 'add' ? 'Add User' : 'Edit User'}
          onSubmit={handleSubmit}
          onCancel={handleCloseAddOrEditUserModal}
          onChange={handleChange}
          data={{
            notesFromCreator,
            firstName,
            lastName,
            password1,
            password2,
            email,
            username,
            role,
            countries //: role === 'CAREWORKER' ? countries[0] : countries
          }}
          countriesDropdownOptions={countriesDropdownOptions}
          rolesDropdownOptions={rolesDropdownOptions}
        />
        <DeletePromptModal
          open={isDeletePromptModalOpen}
          onSubmit={handleDeleteUser}
          onCancel={handleCloseDeletePromptModal}
        />
        <Grid container>
          <Paper className={classes.root} elevation={1}>
            <Typography style={{ marginBottom: 10 }} variant="h5" component="h3">
              Users
              <Fab
                onClick={handleOpenAddUserModal}
                color="secondary"
                aria-label="Add"
                className={classes.fab}
              >
                <AddIcon />
              </Fab>
            </Typography>
            <Divider />
            <div style={{ marginTop: 30 }}>
              <CommonTable
                data={users}
                columns={USER_COLUMNS}
                actions={[
                  {
                    icon: () => <EditIcon />,
                    tooltip: 'Edit User',
                    onClick: handleUpdateUser
                  },
                  {
                    icon: () => <DeleteIcon />,
                    tooltip: 'Delete User',
                    onClick: handleShowDeletePromptModal
                  }
                ]}
              />
            </div>
          </Paper>
        </Grid>
      </React.Fragment>
    );
  };

  return render();
};

export default compose(withStyles(styles))(UsersPage);
