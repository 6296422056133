import React, { useState } from 'react';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import logo from '../../../assets/logo.png';
import LoginService from './LoginService';
import AuthService from '../../../services/auth';
import Config from '../../../config';
import useUserStore from 'state/hooks/use-user-store';
import useToaster from 'state/hooks/use-toaster';
import { useHistory } from 'react-router';

const styles = theme => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    textAlign: 'center',
    margin: 'auto'
  },
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    width: 350,
    height: 400
  },
  textField: {
    width: 280,
    flex: 1
  },
  button: {
    width: 280,
    marginTop: 25
  },
  logo: {
    borderRadius: '50%',
    height: 200,
    width: 200,
    marginTop: -90
  }
});
const LoginPage = props => {
  const [, ToasterContext] = useToaster();
  const [state, setState] = useState({
    username: '',
    password: ''
  });
  const [, { refetchUser }] = useUserStore();
  const history = useHistory();

  const handleChange = e => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const handleKeyPress = e => {
    if (e.key === 'Enter') {
      submit();
    }
  };

  const submit = async () => {
    const { username, password } = state;

    try {
      if (!username || !password) {
        ToasterContext.showErrorToaster({ message: 'Username and Password are required!' });
        return;
      }
      const { data, success, err = null } = await LoginService.login({ username, password });

      if (success && data.user) {
        AuthService.saveToken(data.token);
        await refetchUser();
        history.push('/');
      } else {
        ToasterContext.showErrorToaster({
          message: err.message || 'Are you sure your username and password are correct?'
        });
      }
    } catch (err) {
      ToasterContext.showErrorToaster({
        message: 'An unexpected error occurred. Please fill out an issue.'
      });
    }
  };
  const render = () => {
    const { classes } = props;
    const { username, password } = state;

    return (
      <div className={classes.container}>
        <Paper className={classes.root} elevation={1}>
          <form>
            <div>
              <img src={logo} className={classes.logo} alt="" />
            </div>
            <TextField
              id="username"
              label="Username"
              name="username"
              className={classes.textField}
              value={username}
              onChange={handleChange}
              margin="normal"
            />
            <TextField
              type="password"
              id="password"
              label="Password"
              name="password"
              className={classes.textField}
              value={password}
              onChange={handleChange}
              margin="normal"
              onKeyPress={handleKeyPress}
            />
            <Button
              onClick={submit}
              variant="contained"
              color="secondary"
              className={classes.button}
            >
              Login {Config.env !== 'prod' ? `(${Config.env})` : ''}
            </Button>
          </form>
        </Paper>
      </div>
    );
  };

  return render();
};

export default withStyles(styles as any)(LoginPage);
