import React, { useRef } from 'react';
import MaterialTable, { MTableToolbar } from 'material-table';
import { useHistory } from 'react-router';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { Link } from 'react-router-dom';
import { maybeDate, maybeDateString, maybeTimeString } from '../../util/maybe';
import { tableIcons } from './table-icons';
import { dateSort, sort } from 'util/sort-util';

const DEFAULT_PAGE_NUMBER = 0;

const DEFAULT_PAGE_SIZE = 50;

const CHILDREN_COLUMNS = [
  {
    title: 'Row',
    field: 'i',
    sorting: false,
    render: rowData => (
      <div style={{ color: '#767676', textAlign: 'center' }}>{rowData.tableData.id + 1}</div>
    )
  },
  {
    title: 'Child',
    customSort: sort('name'),
    field: 'name',
    render: rowData => (
      // <div>
      //   <Link to={{ pathname: `/child/${rowData.id}`, state: rowData.previousPageState }}>
      //     <strong>{rowData.name}</strong>
      //   </Link>
      //   <br />
      //   <span style={{ color: '#767676' }}>Child # {rowData.outsideSystemNo}</span>
      // </div>
      <Link to={{ pathname: `/child/${rowData.id}`, state: rowData.previousPageState }}>
        <strong>{rowData.name}</strong>
      </Link>
    )
  },
  {
    title: 'Care Worker',
    customSort: sort('supervisorName'),
    field: 'supervisorName',
    render: rowData => <span>{rowData.supervisorName}</span>
  },
  {
    title: 'Child No',
    sorting: true,
    field: 'outsideSystemNo',
    render: rowData => <span>{rowData.outsideSystemNo}</span>
  },
  {
    title: 'Last Checkup',
    customSort: dateSort('lastCheckupDate'),
    field: 'lastCheckupDate',
    render: rowData => {
      if (maybeDate(rowData.lastCheckupDate) === 'None') {
        return <span style={{ color: '#767676' }}>None</span>;
      }
      return (
        <div>
          <div>{maybeDateString(rowData.lastCheckupDate)}</div>
          <div style={{ color: '#767676' }}>{maybeTimeString(rowData.lastCheckupDate)}</div>
        </div>
      );
    }
  },
  {
    title: 'Last Survey',
    customSort: dateSort('lastSurveyDate'),
    field: 'lastSurveyDate',
    render: rowData => {
      if (maybeDate(rowData.lastSurveyDate) === 'None') {
        return <span style={{ color: '#767676' }}>None</span>;
      }
      return (
        <div>
          <div>{maybeDateString(rowData.lastSurveyDate)}</div>
          <div style={{ color: '#767676' }}>{maybeTimeString(rowData.lastSurveyDate)}</div>
        </div>
      );
    }
    // (
    //   <span style={maybeDate(rowData.lastSurveyDate) === 'None' ? { color: '#767676' } : null}>
    //     {maybeDate(rowData.lastSurveyDate)}
    //   </span>
    // )
  },
  {
    title: 'Site/Country',
    customSort: sort('siteName'),
    field: 'siteCountry',
    render: rowData => (
      <div>
        <div>{rowData.siteName}</div>
        <div style={{ color: '#767676' }}>{rowData.countryName}</div>
      </div>
    )
  },
  // {
  //   title: 'Village, Region',
  //   customSort: sort('villageName'),
  //   field: 'village',
  //   render: rowData => (
  //     <div>
  //       <div>
  //         <span>
  //           {rowData.villageName && rowData.villageName !== rowData.regionName
  //             ? `${rowData.villageName}, `
  //             : ''}
  //         </span>
  //         <span>{rowData.regionName}</span>
  //       </div>
  //     </div>
  //   )
  // },
  {
    title: 'Region',
    customSort: sort('regionName'),
    field: 'regionName',
    render: rowData => <span>{rowData.regionName}</span>
  },
  {
    title: 'Village',
    customSort: sort('villageName'),
    field: 'villageName',
    render: rowData => <span>{rowData.villageName}</span>
  }
];

const ChildrenTable = ({
  handleChange,
  handleAssign,
  selectedSupervisorId,
  usersByRole = [],
  data = [],
  loading = false,
  hasSearch = false,
  options = {},
  ...restProps
}) => {
  const handleAssignChildren = props => () => {
    handleAssign(props.selectedRows);
  };
  const tableRef: any = useRef(MaterialTable);
  const history = useHistory();
  const initialPageNumber = history?.location?.state?.previousPageNumber || DEFAULT_PAGE_NUMBER;
  const initialPageSize = history?.location?.state?.previousPageSize || DEFAULT_PAGE_SIZE;

  const onTableChangePage = (pageNumber: number) => {
    history.replace({
      path: history.location.pathname,
      search: history.location.search,
      state: {
        ...history.location.state,
        previousPageNumber: pageNumber || DEFAULT_PAGE_NUMBER
      }
    });
  };

  const onTableChangeRowsPerPage = (rows: number) => {
    history.replace({
      path: history.location.pathname,
      search: history.location.search,
      state: {
        ...history.location.state,
        previousPageSize: rows || DEFAULT_PAGE_SIZE
      }
    });
  };

  if (hasSearch || loading) {
    return (
      <MaterialTable
        isLoading={loading}
        icons={tableIcons}
        title=""
        columns={CHILDREN_COLUMNS}
        data={data}
        tableRef={tableRef}
        options={{
          ...options,
          selection: !!data.length,
          search: true,
          pageSizeOptions: [5, 10, 20, 50],
          pageSize: initialPageSize,
          initialPage: initialPageNumber
        }}
        onChangePage={onTableChangePage}
        onChangeRowsPerPage={onTableChangeRowsPerPage}
        components={{
          Toolbar: props => {
            return (
              <React.Fragment>
                <MTableToolbar {...props} />
                <div style={{ marginTop: 10, marginLeft: 10 }}>
                  <Button
                    disabled={!selectedSupervisorId}
                    onClick={handleAssignChildren(props)}
                    color="secondary"
                    variant="contained"
                  >
                    Assign Children
                  </Button>
                  <span style={{ position: 'relative', left: 15 }}>
                    <TextField
                      style={{ width: 200 }}
                      select
                      name="selectedSupervisorId"
                      value={selectedSupervisorId || ''}
                      onChange={handleChange}
                      SelectProps={{
                        MenuProps: {}
                      }}
                    >
                      {usersByRole.map(x => (
                        <MenuItem key={x.id} value={x.id}>
                          {x.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </span>
                </div>
              </React.Fragment>
            );
          }
        }}
        {...restProps}
      />
    );
  } else {
    return null;
  }
};

export default ChildrenTable;
