import React from 'react';
import { MULTI_SELECT_TYPE, SELECT_TYPE, TEXT_TYPE, DATE_TYPE } from '../../common/Form';
import { Link } from 'react-router-dom';
import { maybeDate } from '../../../util/maybe';
import { sort, dateSort } from '../../../util/sort-util';
import get from 'lodash/get';

export const getNone = (displayProp = 'display', valueProp = 'value') => ({
  [displayProp]: 'None',
  [valueProp]: null
});

export const getName = user => {
  if (!user) return '';
  return user.firstName + ' ' + user.lastName;
};

const conditionalSurveyInputs = type => {
  if (type !== 'survey-answers') return [];

  return [
    {
      name: 'surveyTemplateId',
      label: 'Survey Template',
      type: SELECT_TYPE,
      layoutOptions: { md: 12 },
      getMenuItemDisplay: x => x.name,
      getMenuItemValue: x => x.id,
      values: [],
      formControl: { test: value => true, err: false, message: 'Required' },
      isDisabled: (item, data) => data.type === 'checkup'
    },
    {
      name: 'domainId',
      label: 'Domain',
      type: SELECT_TYPE,
      layoutOptions: { md: 4 },
      getMenuItemDisplay: x => `${x.name}`,
      getMenuItemValue: x => x.id,
      values: [],
      formControl: { test: value => true, err: false, message: 'Required' },
      isDisabled: (item, data) => data.type === 'checkup'
    },
    {
      name: 'categoryId',
      label: 'Category',
      type: SELECT_TYPE,
      layoutOptions: { md: 4 },
      getMenuItemDisplay: x => `${x.name}`,
      getMenuItemValue: x => x.id,
      values: [],
      formControl: { test: value => true, err: false, message: 'Required' },
      isDisabled: (item, data) => data.type === 'checkup' || !data.domainId
    },
    {
      name: 'scoreValue',
      label: 'Score Value',
      type: MULTI_SELECT_TYPE,
      getMenuItemDisplay: x => x,
      getMenuItemValue: x => x,
      values: [1, 2, 3, 4],
      layoutOptions: { md: 4 },
      formControl: { test: value => true, err: false, message: 'Required' },
      isDisabled: (item, data) => data.type === 'checkup'
    }
  ];
};

const conditionalChildFiltersInputs = type => {
  if (!['survey-answers', 'checkups'].includes(type)) return [];

  return [
    {
      name: 'childName',
      label: 'Child Name',
      type: TEXT_TYPE,
      layoutOptions: { md: 4 },
      formControl: { test: value => true, err: false, message: 'Required' },
      isDisabled: (item, data) => false
    },
    {
      name: 'outsideSystemNo',
      label: 'Child Number',
      type: TEXT_TYPE,
      layoutOptions: { md: 4 },
      formControl: { test: value => true, err: false, message: 'Required' },
      isDisabled: (item, data) => false
    },
    {
      name: 'withChildAlert',
      label: 'With Child Alert',
      type: SELECT_TYPE,
      layoutOptions: { md: 4 },
      getMenuItemDisplay: x => x.display,
      getMenuItemValue: x => x.value,
      values: [getNone(), { display: 'Yes', value: true }, { display: 'No', value: false }],
      formControl: { test: value => true, err: false, message: 'Required' },
      isDisabled: (item, data) => false
    }
  ];
};

const conditionalSurveyViolationsInputs = type => {
  if (type !== 'survey-violations') return [];

  return [
    {
      name: 'viewNoSurveysCompleted',
      label: 'With Surveys Completed',
      type: SELECT_TYPE,
      layoutOptions: { md: 4 },
      getMenuItemDisplay: x => x.display,
      getMenuItemValue: x => x.value,
      values: [
        { display: 'No Surveys Completed', value: true },
        { display: 'Last Date Completed (default)', value: false }
      ],
      formControl: { test: value => true, err: false, message: 'Required' },
      isDisabled: (item, data) => false
    }
  ];
};

const conditionalDateRangeInputs = type => {
  const inputs = [
    {
      name: 'dateBetweenMin',
      label: type === 'survey-violations' ? 'No Surveys Taken Since' : 'Start Date',
      type: DATE_TYPE,
      layoutOptions: { md: 4 },
      formControl: { test: value => true, err: false, message: 'Required' },
      isDisabled: (item, data) => false
    }
  ];

  if (type !== 'survey-violations') {
    inputs.push({
      name: 'dateBetweenMax',
      label: 'End Date',
      type: DATE_TYPE,
      layoutOptions: { md: 4 },
      formControl: { test: value => true, err: false, message: 'Required' },
      isDisabled: (item, data) => false
    });
  }

  return inputs;
};

const getInputs = type => [
  {
    name: 'type',
    label: 'Type',
    type: SELECT_TYPE,
    layoutOptions: { md: 12 },
    getMenuItemDisplay: x => x.display,
    getMenuItemValue: x => x.value,
    values: [
      { display: 'Survey Answers', value: 'survey-answers' },
      { display: 'Checkups', value: 'checkups' },
      { display: 'Survey Violations', value: 'survey-violations' }
    ],
    formControl: { test: value => true, err: false, message: 'Required' },
    isDisabled: (item, data) => false
  },
  ...conditionalSurveyInputs(type),
  {
    name: 'countryId',
    label: 'Country',
    type: SELECT_TYPE,
    layoutOptions: { md: 3 },
    getMenuItemDisplay: x => `${x.name}`,
    getMenuItemValue: x => x.id,
    values: [],
    formControl: { test: value => true, err: false, message: 'Required' },
    isDisabled: (item, data) => false
  },
  {
    name: 'siteId',
    label: 'Site',
    type: SELECT_TYPE,
    layoutOptions: { md: 3 },
    getMenuItemDisplay: x => `${x.name}`,
    getMenuItemValue: x => x.id,
    values: [],
    formControl: { test: value => true, err: false, message: 'Required' },
    isDisabled: (item, data) => !data.countryId
  },
  {
    name: 'regionId',
    label: 'Region',
    type: SELECT_TYPE,
    layoutOptions: { md: 3 },
    getMenuItemDisplay: x => `${x.name}`,
    getMenuItemValue: x => x.id,
    values: [],
    formControl: { test: value => true, err: false, message: 'Required' },
    isDisabled: (item, data) => !data.siteId
  },
  {
    name: 'villageId',
    label: 'Village',
    type: SELECT_TYPE,
    layoutOptions: { md: 3 },
    getMenuItemDisplay: x => `${x.name}`,
    getMenuItemValue: x => x.id,
    values: [],
    formControl: { test: value => true, err: false, message: 'Required' },
    isDisabled: (item, data) => !data.regionId
  },
  {
    name: 'supervisorId',
    label: 'Care Worker',
    type: SELECT_TYPE,
    layoutOptions: { md: 12 },
    getMenuItemDisplay: x => `${x.name}`,
    getMenuItemValue: x => x.id,
    values: [],
    formControl: { test: value => true, err: false, message: 'Required' },
    isDisabled: (item, data) => false
  },
  ...conditionalChildFiltersInputs(type),
  ...conditionalSurveyViolationsInputs(type),
  ...conditionalDateRangeInputs(type)
];

const SURVEY_ANSWERS_COLUMNS = [
  {
    title: 'Date',
    customSort: dateSort('createdOnDate'),
    render: rowData => <span>{maybeDate(rowData.createdOnDate)}</span>
  },
  {
    title: 'Village',
    customSort: sort('child.village.name'),
    render: rowData => <span>{get(rowData, 'child.village.name')}</span>
  },
  {
    title: 'Child Name',
    field: 'name',
    customSort: sort('child.name'),
    render: rowData => <Link to={`/child/${rowData.child.id}`}>{rowData.child.name}</Link>
  },
  {
    title: 'Survey',
    field: 'name',
    render: rowData => (
      <Link
        to={`/survey/${rowData.survey_completed.id}/${rowData.survey_completed.surveyTemplateId}`}
      >
        Go to Survey
      </Link>
    )
  },
  {
    title: 'Domain',
    render: rowData => <span>{rowData.domain.name}</span>,
    customSort: sort('domain.name')
  },
  {
    title: 'Category',
    render: rowData => <span>{rowData.category.name}</span>,
    customSort: sort('category.name')
  },
  {
    title: 'Score',
    field: 'scoreValue',
    render: rowData => <span>{rowData.scoreValue}</span>
  },
  {
    title: 'Current Care Worker',
    customSort: sort('child.user.firstName'),
    render: rowData => <span>{getName(rowData.child.user)}</span>
  },
  {
    title: 'Survey Care Worker',
    customSort: sort('survey_completed.user.firstName'),
    render: rowData => <span>{getName(rowData.survey_completed.user)}</span>
  }
];

const CHECKUP_COLUMNS = [
  {
    title: 'Date',
    customSort: dateSort('createdOnDate'),
    render: rowData => <span>{maybeDate(rowData.createdOnDate)}</span>
  },
  {
    title: 'Village',
    render: rowData => <span>{get(rowData, 'child.village.name')}</span>,
    customSort: sort('child.village.name')
  },
  {
    title: 'Child Name',
    field: 'name',
    customSort: sort('child.name'),
    render: rowData => <Link to={`/child/${rowData.child.id}`}>{rowData.child.name}</Link>
  },
  {
    title: 'Care Worker',
    customSort: sort('child.user.firstName'),
    render: rowData => <span>{getName(rowData.child.user)}</span>
  }
];

const SURVEY_VIOLATION_COLUMNS = [
  {
    title: 'Last Survey Date',
    customSort: dateSort('lastSurveyDate'),
    render: rowData => <span>{maybeDate(rowData.lastSurveyDate)}</span>
  },
  {
    title: 'Village',
    render: rowData => <span>{get(rowData, 'village.name')}</span>,
    customSort: sort('village.name')
  },
  {
    title: 'Child Name',
    field: 'name',
    customSort: sort('name'),
    render: rowData => <Link to={`/child/${rowData.id}`}>{rowData.name}</Link>
  },
  {
    title: 'Care Worker',
    customSort: sort('user.firstName'),
    render: rowData => <span>{getName(rowData.user)}</span>
  }
];

const getColumns = (type = 'survey-answers') => {
  switch (type) {
    case 'survey-answers':
      return SURVEY_ANSWERS_COLUMNS;
    case 'checkups':
      return CHECKUP_COLUMNS;
    case 'survey-violations':
      return SURVEY_VIOLATION_COLUMNS;
  }
};

export { getInputs, getColumns };

// No longer needed, but keeping here just in case
// {
//   name: 'optionId',
//   label: 'Option',
//   type: SELECT_TYPE,
//   layoutOptions: { md: 3 },
//   getMenuItemDisplay: x => `${x.name}`,
//   getMenuItemValue: x => x.id,
//   values: [],
//   formControl: { test: value => true, err: false, message: 'Required' },
//   isDisabled: (item, data) => data.type === 'checkup' || !data.categoryId
// },
// {
//   name: 'withChildAlertMessage',
//   label: 'Child Alert Message',
//   type: TEXT_TYPE,
//   layoutOptions: { md: 3 },
//   formControl: { test: value => true, err: false, message: 'Required' },
//   isDisabled: (item, data) => false
// },
// {
//   name: 'withNeedsAndObservations',
//   label: 'Needs/Observations Message',
//   type: TEXT_TYPE,
//   layoutOptions: { md: 3 },
//   formControl: { test: value => true, err: false, message: 'Required' },
//   isDisabled: (item, data) => false
// },
// {
//   name: 'withNextSteps',
//   label: 'Next Steps Message',
//   type: TEXT_TYPE,
//   layoutOptions: { md: 3 },
//   formControl: { test: value => true, err: false, message: 'Required' },
//   isDisabled: (item, data) => data.type === 'checkup'
// },
