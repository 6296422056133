import { gql } from '@apollo/client';

export default gql`
  query(
    $countryId: Int
    $siteId: Int
    $regionId: Int
    $villageId: Int
    $childName: String
    $showOnlyUnassignedChildren: Boolean
    $outsideSystemNo: String
  ) {
    childrenSearch(
      countryId: $countryId
      siteId: $siteId
      regionId: $regionId
      villageId: $villageId
      name: $childName
      showOnlyUnassignedChildren: $showOnlyUnassignedChildren
      outsideSystemNo: $outsideSystemNo
    ) {
      id
      name
      outsideSystemNo
      villageName
      villageId
      regionName
      regionId
      siteName
      siteId
      countryName
      countryId
      supervisorName
      supervisorId
      lastSurveyDate
      lastCheckupDate
    }
  }
`;
