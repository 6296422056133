import React, { Component } from 'react';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import WarningIcon from '@material-ui/icons/Warning';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';

const ICONS = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon
};

const styles = theme => ({
  success: {
    backgroundColor: green[600]
  },
  error: {
    backgroundColor: theme.palette.error.dark
  },
  info: {
    backgroundColor: theme.palette.primary.dark
  },
  warning: {
    backgroundColor: amber[700]
  },
  icon: {
    fontSize: 20
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing()
  },
  message: {
    display: 'flex',
    alignItems: 'center'
  },
  margin: {
    margin: theme.spacing()
  }
});

/**
 * The TosterProvider should be used to hold view-related state like Toastrs, etc
 */
export const ToasterContext = React.createContext(null);

const UnwrappedSnackbarContent = ({ classes, handleCloseToaster, toasterMessage, type, Icon }) => {
  return (
    <SnackbarContent
      className={classnames(classes[type], classes.margin)} //className
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={classnames(classes.icon, classes.iconVariant)} />
          {toasterMessage}
        </span>
      }
      action={[
        <IconButton key="close" aria-label="Close" color="inherit" onClick={handleCloseToaster}>
          <CloseIcon className={classes.icon} />
        </IconButton>
      ]}
    />
  );
};
const WrappedSnackbarContent = withStyles(styles)(UnwrappedSnackbarContent);

class TosterProvider extends Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      toasterOpen: false,
      toasterMessage: null
    };
  }

  showErrorToaster = ({ message }) => {
    const msg = message.replace('GraphQL error: ', '');
    this.setState({ type: 'error', toasterMessage: msg, toasterOpen: true });
  };

  showSuccessToaster = ({ message }) => {
    this.setState({ type: 'success', toasterMessage: message, toasterOpen: true });
  };

  handleCloseToaster = () => {
    this.setState({ toasterMessage: null, toasterOpen: false });
  };
  render = () => {
    const { toasterMessage, toasterOpen, type } = this.state;
    const Icon = ICONS[type];

    return (
      <ToasterContext.Provider
        value={[
          this.state,
          { showErrorToaster: this.showErrorToaster, showSuccessToaster: this.showSuccessToaster }
        ]}
      >
        {this.props.children}

        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          open={toasterOpen}
          autoHideDuration={6000}
          onClose={this.handleCloseToaster}
        >
          <WrappedSnackbarContent
            handleCloseToaster={this.handleCloseToaster}
            toasterMessage={toasterMessage}
            type={type}
            Icon={Icon}
          />
        </Snackbar>
      </ToasterContext.Provider>
    );
  };
}

export default TosterProvider;
