import { parse as csvParse } from 'json2csv';

export const exportCsv = (data, filename = 'gfdata-export.csv', opts = {}) => {
  try {
    const csvData = csvParse(data, opts);

    const hiddenElement = document.createElement('a');
    hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csvData);
    hiddenElement.target = '_blank';
    hiddenElement.download = filename || 'gfdata-export.csv';
    hiddenElement.click();
  } catch (err) {
    console.error(err);
  }
};
