import { gql } from '@apollo/client'
import { BaseChildAlertFragment } from '../Fragments/child-alert-fragment';

export default gql`
  mutation($childAlertIds: [Int!]!) {
    acknowledgeChildAlerts(childAlertIds: $childAlertIds) {
      ...BaseChildAlert
    }
  }
  ${BaseChildAlertFragment}
`;
