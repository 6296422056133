import { gql } from '@apollo/client'

export default gql`
  query {
    usersByPermissions {
      id
      firstName
      lastName
      name
      username
      role
      countries {
        name
        id
      }
    }
  }
`;
