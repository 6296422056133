import { useContext } from 'react';
import { ToasterContext } from '../providers/toaster';

const useToaster = () => {
  const [state, dispatch] = useContext(ToasterContext);

  return [state, dispatch];
};

export default useToaster;
