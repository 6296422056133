/***
 * This service contains all auth-related methods, local storage persistent methods, etc.
 */

const TOKEN_NAME = 'globalfingerprints-session';

class AuthService {
  getCurrentUser() {
    if (this.isLoggedIn()) {
      const token = this.getToken();
      const payload = this.decodeToken(token);

      return payload;
    }
  }

  decodeToken(token) {
    let payload = token.split('.')[1];
    payload = window.atob(payload);
    payload = JSON.parse(payload);

    return payload;
  }

  parseToken(payload) {
    const token = { ...payload };

    if (token.countries && typeof token.countries === 'string') {
      token.countries = JSON.parse(token.countries);
    }

    return token;
  }
  saveToken(token) {
    window.localStorage[TOKEN_NAME] = token;
  }

  getToken() {
    return window.localStorage[TOKEN_NAME] || null;
  }

  updateToken(token) {
    window.localStorage.removeItem(TOKEN_NAME);
    window.localStorage[TOKEN_NAME] = token;
  }

  deleteToken() {
    if (this.getToken()) window.localStorage.removeItem(TOKEN_NAME);
  }

  logout() {
    this.deleteToken();
  }

  setItemLocalStorage(itemName, value) {
    window.localStorage.setItem(itemName, value);
  }

  isTokenValid(decodedToken) {
    if (decodedToken.expiry) {
      const now = new Date().getMilliseconds();
      const expiry = new Date(decodedToken.expiry).getMilliseconds();
      const nowSkew = now + 36000000; // 60 mins

      if (nowSkew > expiry) return false;
      else return true;
    } else return true;
  }

  isLoggedIn() {
    const token = this.getToken();

    if (token) {
      // check expiry date
      // let decodedToken = this.decodeToken(token);
      // if (this.isTokenValid(decodedToken)) return true;
      // else return false;
      return true;
    } else return false;
  }
}

export default new AuthService();
