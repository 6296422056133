import RestApi from '../../../services/rest-api';
import { CONSTANT } from '../../../constant';

const login = ({ username, password }) => {
  return RestApi.post(`/auth/login`, {
    username,
    password,
    application: CONSTANT.APPLICATION_NAME
  });
};
export default {
  login
};
