import React from 'react';
import { Redirect } from 'react-router';
import Main from 'components/Layout/Main';
import useUserStore from 'state/hooks/use-user-store';
import { CircularProgress, Typography } from '@material-ui/core';

const AuthorizedRoute = () => {
  const [{ isAuthenticated, isAuthenticating }] = useUserStore();

  return (
    <>
      {isAuthenticated ? (
        <Main />
      ) : !isAuthenticated && isAuthenticating ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%'
          }}
        >
          <CircularProgress />
          <Typography variant="h5" component="h3">
            Loading...
          </Typography>
        </div>
      ) : (
        <Redirect to="/login" />
      )}
    </>
  );
};

export default AuthorizedRoute;
