import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

const CommonLoader = ({ text = 'Loading...' }) => {
  return (
    <div
      style={{
        alignItems: 'center',
        display: 'flex'
      }}
    >
      <CircularProgress style={{ marginRight: 20 }} />
      <Typography variant="h6" component="h3">
        {text}
      </Typography>
    </div>
  );
};

export default CommonLoader;
