import get from 'lodash/get';
import { maybeDate } from './maybe';

export const sort = propertyPath => (a, b) => {
  const aValue = get(a, propertyPath);
  const bValue = get(b, propertyPath);

  if (!aValue && bValue) return -1;
  if (aValue && !bValue) return 1;
  if (!aValue && !bValue) return 0;

  return aValue.localeCompare(bValue);
};

export const dateSort = propertyPath => (a, b) => {
  let aValue = maybeDate(get(a, propertyPath));
  let bValue = maybeDate(get(b, propertyPath));

  if (aValue === 'None') aValue = null;
  if (bValue === 'None') bValue = null;

  if (!aValue && bValue) return -1;
  if (aValue && !bValue) return 1;
  if (!aValue && !bValue) return 0;

  return new Date(aValue).getTime() - new Date(bValue).getTime();
};
