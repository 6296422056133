import { gql } from '@apollo/client';

export default gql`
  query {
    allSitesByPermissions {
      id
      countryId
      name
    }
  }
`;
