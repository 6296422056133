import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import ButtonToolbar from '../../common/ButtonToolbar';
import RestApi from '../../../services/rest-api';
import { flowRight as compose } from 'lodash';
import { withRouter, useHistory } from 'react-router-dom';
import FormHelperText from '@material-ui/core/FormHelperText';
import AuthService from '../../../services/auth';
import useUserStore from 'state/hooks/use-user-store';
import useToaster from 'state/hooks/use-toaster';

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    width: '80%'
  },
  textField: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
    width: 400
  },
  textarea: {
    height: '400px'
  },
  formLabel: {
    marginLeft: 6
  }
});

const SettingsPage = props => {
  const [, ToasterContext] = useToaster();
  const history = useHistory();
  const [{ me }, { refetchUser }] = useUserStore();
  const [state, setState] = useState({
    email: me.email,
    username: me.username,
    firstName: me.firstName,
    lastName: me.lastName,
    password1: '',
    password2: '',
    currentPassword: ''
  });

  const handleSubmit = async () => {
    const { password1, password2, currentPassword, firstName, lastName, email, username } = state;
    let errMessage;

    // TODO: this could be refactored to use validate and err labels on the form fields
    if ((password1 || password2) && password1 !== password2)
      errMessage = 'New passwords do not match!';
    else if (password1 && password2 && !currentPassword)
      errMessage = "You didn't enter in your current password!";
    else if (!email || !username || !firstName || !lastName)
      errMessage = 'Email, Username, First Name, and Last Name are required fields';

    if (errMessage) {
      ToasterContext.showErrorToaster({ message: errMessage });
      return;
    }
    try {
      const { data } = await RestApi.post(`/user`, {
        ...(password1 && { password1 }),
        ...(password2 && { password2 }),
        ...(currentPassword && { currentPassword }),
        ...(firstName && { firstName }),
        ...(lastName && { lastName }),
        ...(email && { email }),
        ...(username && { username })
      });

      AuthService.saveToken(data.token);
      await refetchUser();
      ToasterContext.showSuccessToaster({ message: 'Settings saved successfully!' });

      history.push('/');
    } catch (err) {
      ToasterContext.showErrorToaster({ message: 'Uh oh! Unexpected error occurred.' });
    }
  };

  const handleChange = e => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const render = () => {
    const { classes } = props;
    const { currentPassword, password1, password2, firstName, lastName, email, username } = state;
    return (
      <Grid container>
        <Paper className={classes.root} elevation={1}>
          <Typography variant="h5" component="h3">
            User Settings and Information
          </Typography>
          <Typography
            variant="subtitle1"
            style={{ marginTop: 30, marginBottom: 30, color: 'gray' }}
          >
            My role: {me.role}
          </Typography>
          <Typography component="div">
            <Grid item sm={12} md={12}>
              <TextField
                id="first-name"
                //label="First Name"
                name="firstName"
                className={classes.textField}
                value={firstName}
                onChange={handleChange}
                margin="normal"
              />
              <FormHelperText className={classes.formLabel}>First Name</FormHelperText>
              <TextField
                id="last-name"
                //label="Last Name"
                name="lastName"
                className={classes.textField}
                value={lastName}
                onChange={handleChange}
                margin="normal"
              />
              <FormHelperText className={classes.formLabel}>Last Name</FormHelperText>
            </Grid>
            <Grid item sm={12} md={12}>
              <TextField
                id="password-1"
                //label="New Password"
                name="password1"
                className={classes.textField}
                value={password1}
                onChange={handleChange}
                margin="normal"
                type="password"
              />
              <FormHelperText className={classes.formLabel}>New Password</FormHelperText>
              <TextField
                id="password-2"
                //label="Re-Enter New Password"
                name="password2"
                className={classes.textField}
                value={password2}
                onChange={handleChange}
                margin="normal"
                type="password"
              />
              <FormHelperText className={classes.formLabel}>Re-Enter New Password</FormHelperText>
              <TextField
                id="password-2"
                //label="Current Password"
                name="currentPassword"
                className={classes.textField}
                value={currentPassword}
                onChange={handleChange}
                margin="normal"
                type="password"
              />
              <FormHelperText className={classes.formLabel}>Current Password</FormHelperText>
            </Grid>
            <Grid item sm={12} md={12}>
              <TextField
                id="email"
                //label="Email"
                name="email"
                className={classes.textField}
                value={email}
                onChange={handleChange}
                margin="normal"
              />
              <FormHelperText className={classes.formLabel}>Email</FormHelperText>
              <TextField
                id="username"
                //label="Username"
                name="username"
                className={classes.textField}
                value={username}
                onChange={handleChange}
                margin="normal"
              />
              <FormHelperText className={classes.formLabel}>Username</FormHelperText>
            </Grid>
          </Typography>
          <ButtonToolbar handleSubmit={handleSubmit} />
        </Paper>
      </Grid>
    );
  };

  return render();
};

export default compose(withStyles(styles), withRouter)(SettingsPage);
