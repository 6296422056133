import React from 'react';
import Divider from '@material-ui/core/Divider';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import List from '@material-ui/core/List';
import Drawer from '@material-ui/core/Drawer';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import routes from '../../routes';
import Config from '../../config';
import { useHistory } from 'react-router';
import useUserStore from 'state/hooks/use-user-store';

const drawerWidth = 240;

const styles = theme => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap'
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: 'hidden',
    width: theme.spacing(8),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing() * 9 + 1
    }
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar
  }
});

const Sidebar = props => {
  const [{ me }] = useUserStore();
  const history = useHistory();

  const handleSidebarListItemClick = x => () => {
    history.push(x.path);
  };

  const renderSidebarListItems = (x, index) => {
    return (
      <ListItem onClick={handleSidebarListItemClick(x)} button key={index}>
        <ListItemIcon>{x.icon}</ListItemIcon>
        <ListItemText primary={x.name} />
      </ListItem>
    );
  };

  const routeMenuItemFilter = x => {
    if (!me) return false;
    return x.menuItem && (!x.permissions ? true : x.permissions.find(role => role === me.role));
  };

  const render = () => {
    const { classes, theme, open, handleDrawerClose } = props;

    return (
      <Drawer
        variant="permanent"
        className={classNames(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open
        })}
        classes={{
          paper: classNames({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open
          })
        }}
        open={open}
      >
        <div className={classes.toolbar}>
          <span style={{ color: 'lightgray', fontSize: 10, position: 'relative', right: 100 }}>
            Version: {Config.version}
          </span>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </div>
        <Divider />
        <List>{routes.filter(routeMenuItemFilter).map(renderSidebarListItems)}</List>
      </Drawer>
    );
  };

  return render();
};

export default withStyles(styles as any, { withTheme: true })(Sidebar);
