import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Sidebar from './Sidebar';
import NavAppBar from './AppBar';
import { Redirect } from 'react-router-dom';
import { flowRight as compose } from 'lodash';
import { Route, Switch, useHistory } from 'react-router';
import routes from '../../routes';

const FULL_PAGES = ['/login'];
const styles = theme => ({
  root: {
    display: 'flex',
    marginBottom: 30
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3)
  }
});

const renderRoutes = () => ({ component: Component, path, ...parentProps }) => {
  return (
    <Route
      exact
      key={path}
      path={path}
      render={props => <Component {...{ ...parentProps, ...props }} />}
    />
  );
};

const AppRoutes = ({ routes }) => {
  return <>{routes.map(renderRoutes())}</>;
};

const Main = props => {
  const history = useHistory();
  const [state, setState] = useState({
    open: false
  });

  const handleDrawerOpen = () => {
    setState({ ...state, open: true });
  };

  const handleDrawerClose = () => {
    setState({ ...state, open: false });
  };

  const maybeAppAndNavbar = Component => {
    const noAppAndNavbar = FULL_PAGES.find(x => x === history.location.pathname);

    return noAppAndNavbar ? <div></div> : <Component />;
  };

  const render = () => {
    const { classes } = props;
    const { root, content, toolbar, ...classesWithoutRoot } = classes;
    const { open } = state;
    const appBarAndNavBar = props => () => (
      <div>
        <CssBaseline />
        <NavAppBar
          {...props}
          handleDrawerOpen={handleDrawerOpen}
          open={open}
          classes={classesWithoutRoot}
        />
        <Sidebar
          {...props}
          handleDrawerClose={handleDrawerClose}
          open={open}
          classes={classesWithoutRoot}
        />
      </div>
    );

    return (
      <div className={root}>
        {maybeAppAndNavbar(appBarAndNavBar(props))}
        <main className={content}>
          <div className={toolbar} />
          <Switch>
            <Route path="/" component={() => <AppRoutes routes={routes} />} />
            <Redirect
              to={{
                pathname: '/',
                state: { referrer: '' } // access in DashboardPage via: this.props.location.state.referrer
              }}
            />
          </Switch>
        </main>
      </div>
    );
  };

  return render();
};

export default compose(withStyles(styles, { withTheme: true }))(Main);
