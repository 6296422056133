import { gql } from '@apollo/client'

export const BaseUserFragment = gql`
  fragment BaseUser on User {
    id
    firstName
    lastName
    username
    email
    notesFromCreator
    role
    countries {
      name
      id
    }
  }
`;
