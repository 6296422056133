import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Input from '@material-ui/core/Input';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import classNames from 'classnames';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import { MaybeControlLabel } from '../common/MaybeControlLabel';

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    width: '80%'
  },
  textField: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
    width: 400
  },
  formControl: {
    margin: theme.spacing(),
    width: 400
  },
  textarea: {
    // height: '400px'
  }
});

class AddOrEditUserModal extends Component<any, any> {
  handleSubmit = onSubmit => () => {
    const { type } = this.props;
    onSubmit({ type });
  };

  handleCancel = onCancel => () => {
    onCancel();
  };

  getDefaultSelectedCountriesTextRenderValue = (selected, countriesDropdownOptions) => {
    // changed this to reduce because of bug where new countries aren't added to user_countries
    return selected
      .reduce((acc, x) => {
        const country = countriesDropdownOptions.find(y => y.id === x);

        return country ? [...acc, country.name] : acc;
      }, [])
      .join(', ');
  };

  render = () => {
    const {
      classes,
      open = false,
      title,
      onChange: handleChange,
      onSubmit,
      onCancel,
      rolesDropdownOptions,
      countriesDropdownOptions,
      formControls,
      data: {
        notesFromCreator,
        firstName,
        lastName,
        password1,
        password2,
        email,
        username,
        role,
        countries = []
      }
    } = this.props;

    return (
      <Dialog
        fullWidth={true}
        maxWidth={'xl'}
        open={open}
        onClose={this.handleCancel}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title" style={{ margin: 'auto', textAlign: 'center' }}>
          {title}
        </DialogTitle>
        <DialogContent>
          <Typography component="div">
            <Typography variant="h6" style={{ marginTop: 25 }}>
              About User
            </Typography>
            <Divider />
            <Grid container>
              <Grid item md={6}>
                <TextField
                  error={formControls.firstName.err}
                  id="first-name"
                  label="First Name"
                  name="firstName"
                  className={classes.textField}
                  value={firstName}
                  onChange={handleChange}
                  margin="normal"
                />
                <MaybeControlLabel
                  visible={formControls.firstName.err}
                  message={formControls.firstName.message}
                />
              </Grid>
              <Grid item md={6}>
                <TextField
                  error={formControls.lastName.err}
                  id="last-name"
                  label="Last Name"
                  name="lastName"
                  className={classes.textField}
                  value={lastName}
                  onChange={handleChange}
                  margin="normal"
                />
                <MaybeControlLabel
                  visible={formControls.lastName.err}
                  message={formControls.lastName.message}
                />
              </Grid>
            </Grid>
            {this.props.passwordFieldsVisible && (
              <Grid container>
                <Grid item md={6}>
                  <TextField
                    error={formControls.password1.err}
                    id="password-1"
                    label="Password"
                    name="password1"
                    className={classes.textField}
                    value={password1}
                    onChange={handleChange}
                    margin="normal"
                    type="password"
                  />
                  <MaybeControlLabel
                    visible={formControls.password1.err}
                    message={formControls.password1.message}
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    error={formControls.password2.err}
                    id="password-2"
                    label="Re-Enter Password"
                    name="password2"
                    className={classes.textField}
                    value={password2}
                    onChange={handleChange}
                    margin="normal"
                    type="password"
                  />
                  <MaybeControlLabel
                    visible={formControls.password2.err}
                    message={formControls.password2.message}
                  />
                </Grid>
              </Grid>
            )}
            <Grid container>
              <Grid item md={6}>
                <TextField
                  id="email"
                  label="Email"
                  name="email"
                  className={classes.textField}
                  value={email}
                  onChange={handleChange}
                  margin="normal"
                  type="email"
                />
              </Grid>
              <Grid item md={6}>
                <TextField
                  error={formControls.username.err}
                  id="username"
                  label="Username"
                  name="username"
                  className={classes.textField}
                  value={username}
                  onChange={handleChange}
                  margin="normal"
                />
                <MaybeControlLabel
                  visible={formControls.username.err}
                  message={formControls.username.message}
                />
              </Grid>
            </Grid>

            <Typography variant="h6" style={{ marginTop: '25px' }}>
              Permissions
            </Typography>
            <Divider />
            <Grid container>
              <Grid item md={6}>
                <TextField
                  error={formControls.role.err}
                  select
                  label="Roles"
                  name="role"
                  value={role}
                  onChange={handleChange}
                  SelectProps={{
                    MenuProps: {}
                  }}
                  helperText="Select Role"
                  margin="normal"
                  className={classes.textField}
                >
                  {rolesDropdownOptions.map(x => (
                    <MenuItem key={x} value={x}>
                      {x}
                    </MenuItem>
                  ))}
                </TextField>
                <MaybeControlLabel
                  visible={formControls.role.err}
                  message={formControls.role.message}
                />

                {role === 'CAREWORKER' ? (
                  <Grid item md={6}>
                    <TextField
                      error={formControls.countries.err}
                      select
                      label="Countries"
                      name="countries"
                      value={countries}
                      onChange={handleChange}
                      SelectProps={{
                        MenuProps: {
                          // className: classes.menu,
                        }
                      }}
                      helperText="Countries"
                      margin="normal"
                      className={classes.textField}
                    >
                      {countriesDropdownOptions.map(x => (
                        <MenuItem key={x} value={x.id}>
                          {x.name}
                        </MenuItem>
                      ))}
                    </TextField>
                    <MaybeControlLabel
                      visible={formControls.countries.err}
                      message={formControls.countries.message}
                    />
                  </Grid>
                ) : (
                  <Grid item md={6}>
                    <FormControl className={classes.formControl}>
                      <InputLabel htmlFor="select-multiple-checkbox">Countries</InputLabel>
                      <Select
                        error={formControls.countries.err}
                        multiple={true}
                        name="countries"
                        value={countries}
                        onChange={handleChange}
                        input={<Input id="select-multiple-checkbox" />}
                        renderValue={selected =>
                          this.getDefaultSelectedCountriesTextRenderValue(
                            selected,
                            countriesDropdownOptions
                          )
                        }
                      >
                        {countriesDropdownOptions.map(x => (
                          <MenuItem key={x.name} value={x.id}>
                            <Checkbox checked={countries && countries.indexOf(x.id) > -1} />
                            <ListItemText primary={x.name} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <MaybeControlLabel
                      visible={formControls.countries.err}
                      message={formControls.countries.message}
                    />
                  </Grid>
                )}
              </Grid>
              <Grid item md={6}>
                <TextField
                  multiline
                  rowsMax="4"
                  id="notesFromCreator"
                  label="Notes"
                  name="notesFromCreator"
                  className={classNames(classes.textField, classes.textarea)}
                  value={notesFromCreator}
                  onChange={handleChange}
                  margin="normal"
                />
              </Grid>
            </Grid>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleCancel(onCancel)} color="primary">
            Cancel
          </Button>
          <Button onClick={this.handleSubmit(onSubmit)} color="secondary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    );
  };
}

export default withStyles(styles)(AddOrEditUserModal);
