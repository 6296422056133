import React, { useEffect, useState } from 'react';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { withStyles } from '@material-ui/core/styles';
import { withRouter, useRouteMatch } from 'react-router-dom';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ErrorIcon from '@material-ui/icons/Error';
import { flowRight as compose } from 'lodash';
import FindSurveyCompletedById from '../../../graphql/CompletedSurvey/find-by-id-query';
import { useQuery } from '@apollo/client';
import { CircularProgress } from '@material-ui/core';
import cloneDeep from 'lodash/cloneDeep';

const COLOR_MAP = {
  primary: '#488aff',
  secondary: '#32db64',
  danger: '#f53d3d',
  warning: '#ffd900',
  light: '#f4f4f4',
  dark: '#222',
  csi: '#18374B',

  // COLORS not accounted for
  score5: '#18374B',
  score6: '#18374B',
  score7: '#18374B',
  score8: '#18374B',
  score9: '#18374B',
  score10: '#18374B'
};

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  'is-checked': {
    backgroundColor: 'rgb(248, 222, 188)',
    padding: 4
  },
  isChecked4: {
    backgroundColor: COLOR_MAP.secondary,
    padding: 4
  },
  isChecked3: {
    backgroundColor: COLOR_MAP.csi,
    padding: 4
  },
  isChecked2: {
    backgroundColor: COLOR_MAP.warning,
    padding: 4
  },
  isChecked1: {
    backgroundColor: COLOR_MAP.danger,
    padding: 4
  },
  domainName: {
    fontSize: 22,
    fontWeight: 'bold'
  },
  ratingText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
});

const buildSurvey = ({ survey, surveyTemplate, childAlerts }) => {
  survey.survey_answers.forEach(answer => {
    surveyTemplate.domains.forEach(a => {
      if (a.id === answer.domainId) {
        a.childAlert = childAlerts.find(x => x.domainId === a.id);

        if (answer.message1) {
          a.message1 = answer.message1;
        }
        if (answer.message2) {
          a.message2 = answer.message2;
        }

        a.categories.forEach(b => {
          if (b.id === answer.categoryId) {
            b.options.forEach(c => {
              if (c.id === answer.optionId) {
                c.isChecked = true;
              } else {
                c.isChecked = false;
              }
            });
          }
        });
      }
    });
  });

  return surveyTemplate;
};
const CheckupPage = props => {
  const match = useRouteMatch<any>();
  const {
    data: {
      surveyCompleted,
      childAlertsBySurveyCompletedId: childAlerts,
      surveyTemplate
    } = {} as any
  } = useQuery(FindSurveyCompletedById, {
    variables: {
      id: +match.params.id,
      surveyTemplateId: +match.params.surveyTemplateId
    }
  });
  const [survey, setSurvey] = useState(null);

  const renderDomains = x => {
    return (
      <ListItem>
        <ListItemText
          primary={
            <div>
              <div
                style={{ backgroundColor: 'lightgray', padding: 6 }}
                className={props.classes.domainName}
              >
                <h2>{x.name}</h2>
              </div>
              <div style={{ margin: 5 }}>
                {x.childAlert && (
                  <span style={{ color: 'red' }}>
                    <ErrorIcon style={{ color: 'red' }} />
                    {x.childAlert && x.childAlert.message}
                  </span>
                )}
              </div>
              {x.categories.map(renderCategories)}
              {(x.message1 || x.message2) && renderMessage(x)}
            </div>
          }
        />
      </ListItem>
    );
  };

  const renderCategories = (x, index) => {
    return (
      <React.Fragment>
        {index % 2 !== 0 && <Divider />}
        <div style={{ margin: 10 }}>
          <h3 style={{ fontSize: 15 }}>{x.name}</h3>
          {x.options.map(renderCategoryOptions)}
        </div>
      </React.Fragment>
    );
  };

  const renderCategoryOptions = x => {
    const className = x.isChecked ? `isChecked${x.scoreValue}` : '';
    return (
      <div className={props.classes[className]}>
        <p>
          <b>{x.scoreDescription}</b>
        </p>
        <p className={props.classes.ratingText}>{x.name}</p>
      </div>
    );
  };

  const renderMessage = x => {
    return (
      <div style={{ padding: 20 }}>
        {(x.message1 || x.message2) && <ErrorIcon style={{ color: 'darkblue' }} />}
        {x.message1 && (
          <div>
            <strong>Needs / Observations</strong>
            <p>{x.message1}</p>
          </div>
        )}
        {x.message2 && (
          <div>
            <strong>Next Steps</strong>
            <p>{x.message2}</p>
          </div>
        )}
      </div>
    );
  };

  const { classes } = props;

  useEffect(() => {
    if (surveyCompleted && surveyTemplate && childAlerts) {
      setSurvey(
        buildSurvey({
          survey: surveyCompleted,
          surveyTemplate: cloneDeep(surveyTemplate),
          childAlerts
        })
      );
    }
  }, [setSurvey, surveyCompleted, surveyTemplate, childAlerts]);

  if (!surveyCompleted) return <CircularProgress />;

  return (
    <Paper className={classes.root} elevation={1}>
      <Typography variant="h5" component="h3">
        Survey
      </Typography>
      <List component="nav">{survey?.domains?.map(renderDomains)}</List>
    </Paper>
  );
};

export default compose(withStyles(styles as any), withRouter)(CheckupPage);
