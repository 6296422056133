import _ from 'lodash';

import { getDefaultChartData, YEARS } from './dashboard-variables';
import { exportCsv } from 'util/export-util';
import { maybeDate } from 'util/maybe';
import { getName } from '../../Reports/reports-config';

export const makeDefaultChartData = years => {
  return years.reduce((acc, year) => {
    acc[year] = { ...getDefaultChartData() };
    return acc;
  }, {});
};

const getCountryKey = id => `country-${id}`;
const getSiteKey = id => `site-${id}`;

export const createChartDataEntityMap = ({
  entities,
  countries,
  sites = [],
  keyByDate = 'createdOnDate'
}) => {
  const siteMap = sites.reduce(
    (acc, x) =>
      acc[x.id]
        ? acc
        : {
            ...acc,
            [getSiteKey(x.id)]: makeDefaultChartData(YEARS)
          },
    {}
  );
  const countryMap = countries.reduce(
    (acc, x) =>
      acc[x.id]
        ? acc
        : {
            ...acc,
            [getCountryKey(x.id)]: makeDefaultChartData(YEARS)
          },
    {}
  );
  const entityMap = { ...siteMap, ...countryMap };

  for (const x of entities) {
    try {
      const year = new Date(parseInt(x[keyByDate])).getFullYear();
      const month = new Date(parseInt(x[keyByDate])).getMonth();

      if (x && x.countryId && year && month >= 0) {
        if (!entityMap[getCountryKey(x.countryId)][year][month]) {
          entityMap[getCountryKey(x.countryId)][year][month] = 1;
        } else {
          entityMap[getCountryKey(x.countryId)][year][month] =
            entityMap[getCountryKey(x.countryId)][year][month] + 1;
        }
      }

      if (x && x.siteId && year && month >= 0) {
        if (!entityMap[getSiteKey(x.siteId)][year][month]) {
          entityMap[getSiteKey(x.siteId)][year][month] = 1;
        } else {
          entityMap[getSiteKey(x.siteId)][year][month] =
            entityMap[getSiteKey(x.siteId)][year][month] + 1;
        }
      }
    } catch (err) {
      // do nothing
    }
  }

  return entityMap;
};

export const loadChartDataByCountryId = (entityMap, countryId, year) => {
  return Object.keys(entityMap[getCountryKey(countryId)][year]).map(
    x => entityMap[getCountryKey(countryId)][year][x]
  );
};

export const loadChartDataBySiteId = (entityMap, siteId, year) => {
  return Object.keys(entityMap[getSiteKey(siteId)][year]).map(
    x => entityMap[getSiteKey(siteId)][year][x]
  );
};

export const exportData = (columns, data, type) => {
  let jsonData = [];
  let filename = null;
  if (type === 'childAlerts') {
    filename = 'gfdata-export-childAlerts.csv';
    jsonData = data.map(x => ({
      'Created on Date': maybeDate(x.createdOnDate),
      'Child Alert Message': x.message,
      'Child Name': x.child.name,
      'Care Worker': _.get(x, 'child.user.firstName', '') + _.get(x, 'child.user.lastName', ''),
      'Country Name': _.get(x, 'child.country.name', ''),
      'Village Name': _.get(x, 'child.village.name', ''),
      Acknowledged: x.isAcknowledged
    }));
  } else if (type === 'surveyViolations') {
    filename = 'gfdata-export-surveyViolations.csv';
    jsonData = data.map(x => ({
      'Last Survey Date': maybeDate(x.lastSurveyDate),
      'Child Name': getName(x.user),
      'Care Worker': _.get(x, 'user.firstName', '') + _.get(x, 'user.lastName', ''),
      'Country Name': _.get(x, 'country.name', ''),
      'Village Name': _.get(x, 'village.name', '')
    }));
  } else if (type === 'checkupViolations') {
    filename = 'gfdata-export-checkupViolations.csv';
    jsonData = data.map(x => ({
      'Last Checkup Date': maybeDate(x.lastCheckupDate),
      'Child Name': x.name,
      'Care Worker': _.get(x, 'user.firstName', '') + _.get(x, 'user.lastName', ''),
      'Country Name': _.get(x, 'country.name', ''),
      'Village Name': _.get(x, 'village.name', '')
    }));
  } else if (type === 'messageViolations') {
    filename = 'gfdata-export-messageViolations.csv';
    jsonData = data.map(x => ({
      'Last Message Date': maybeDate(x.createdOnDate),
      'Child Name': x.name,
      'Care Worker': _.get(x, 'user.firstName', '') + _.get(x, 'user.lastName', ''),
      'Country Name': _.get(x, 'country.name', ''),
      'Village Name': _.get(x, 'village.name', '')
    }));
  } else if (type === 'imageViolations') {
    filename = 'gfdata-export-imageViolations.csv';
    jsonData = data.map(x => ({
      'Last Image Date': maybeDate(x.createdOnDate),
      'Child Name': x.name,
      'Care Worker': _.get(x, 'user.firstName', '') + _.get(x, 'user.lastName', ''),
      'Country Name': _.get(x, 'country.name', ''),
      'Village Name': _.get(x, 'village.name', '')
    }));
  }

  exportCsv(jsonData, filename);
};
