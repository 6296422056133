import { gql } from '@apollo/client'
import { BaseSurveyTemplateEntityFragment } from '../Fragments/survey-template-entity';

export default gql`
  query($surveyTemplateId: Int) {
    surveyTemplate(surveyTemplateId: $surveyTemplateId) {
      ...BaseSurveyTemplate
    }
  }
  ${BaseSurveyTemplateEntityFragment}
`;
