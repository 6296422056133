import React, { useState } from 'react';
import { flowRight as compose } from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import * as _ from 'lodash';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Input from '@material-ui/core/Input';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import { validate } from '../../util/form-validator';
import { MaybeControlLabel } from './MaybeControlLabel';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import SaveCancelButtonToolbar from './SaveCancelButtonToolbar';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';

export const MULTI_SELECT_TYPE = 'multi-select';
export const SELECT_TYPE = 'select';
export const TEXT_TYPE = 'text';
export const NUMBER_TYPE = 'number';
export const DATE_TYPE = 'date';
export const JSON_TYPE = 'json';

const styles = theme => ({
  paper: {
    padding: theme.spacing(2),
    position: 'relative'
  },
  Grid: {
    marginBottom: 25
  },
  input: {
    marginBottom: 10,
    width: '90%'
  },
  inputFullWidth: {
    marginBottom: 10,
    width: '100%'
  },
  menu: {
    right: 0,
    position: 'absolute'
  },
  formControl: {}
});
const getDefaultState = () => ({
  data: {},
  internalFormControls: null,
  dirty: false,
  controlledDataIsPristine: true
});
const typeMapSeedValue = {
  [TEXT_TYPE]: null,
  [NUMBER_TYPE]: null,
  [JSON_TYPE]: null,
  [SELECT_TYPE]: null,
  [MULTI_SELECT_TYPE]: [],
  [DATE_TYPE]: null
};
const getGridLayoutOptions = layoutOptions => ({
  xs: layoutOptions.xs || 12,
  sm: layoutOptions.sm || 12,
  md: layoutOptions.md || 12
});
const sanitizeMultiSelectDisplay = str => (str.length >= 100 ? `${str.slice(0, 100)}...` : str);
export const getInitialDataValue = x => typeMapSeedValue[x.type];
const sanitize = value => (value === undefined || value === null ? '' : value);
// compareMenuItems -> primitive comparison -> id -> 3 different item.getValue comparisons
const compareMultiSelectValues = (inputEl, item1, item2) => {
  if (inputEl.compareMenuItems) {
    return inputEl.compareMenuItems(item1, item2);
  }
  if (typeof item1 !== 'object' && typeof item2 !== 'object') {
    return item1 === item2;
  }
  if (item1.id && item1.id) {
    return item1.id === item2.id;
  }
  if (item1.getValue && item2.getValue) {
    return item1.getValue(item1) === item2.getValue(item2);
  }
  if (item1.getValue && (typeof item2 === 'string' || typeof item2 === 'number')) {
    return item1.getValue() === item2;
  }
  if ((typeof item1 === 'string' || typeof item1 === 'number') && item2.getValue) {
    return item1 === item2.getValue();
  }
  throw new Error(`
    Please provide a way to compare your multi-select items. The comparison fn follows this order:
      1.) comparison fn - (provided at root level of input element and takes 2 args): inputEl.compareMenuItems(item1, item2)
      2.) Primitive     -  item1 === item2
      3.) id comparison -  item1.id === item2.id
      4.) getValue fn   -  
              a.) item1.getValue() === item2.getValue()
              b.) item1.getValue() === item2
              c.) item2.getValue() === item1
  `);
};
// inputEl get display -> primitive -> display prop -> item getDisplay
const getSelectDisplay = (inputEl, item) => {
  if (inputEl.getMenuItemDisplay) {
    return inputEl.getMenuItemDisplay(item);
  }
  if (item.getDisplay) {
    return item.getDisplay(item);
  }
  if (typeof item !== 'object') {
    return item;
  }
  if (item.display) {
    return item.display;
  }
};
// inputEl get value -> item.getValue -> primitive
const getSelectValue = (inputEl, item) => {
  if (inputEl.getMenuItemValue) {
    return inputEl.getMenuItemValue(item);
  }
  if (item.getValue) {
    return item.getValue(item);
  }
  if (typeof item !== 'object') {
    return item;
  }
};
function LwtForm(props) {
  const [state, setState] = useState(getDefaultState());
  const propogateBeforeChange = async formData =>
    props.beforeChange && props.beforeChange(formData);
  const propogateAfterChange = async data => props.onChange && props.onChange(data);
  const handleChange = async e => {
    const data = props.manageStateExternally ? props.entity : state.data;
    const {
      target: { name, value }
    } = e;
    const acceptChange = await propogateBeforeChange({ name, value, data });
    if (acceptChange === false) return;
    // THIS IS A HACK - need to be able to run this sync
    const savedChanges = { ...data, [name]: value };

    if (props.manageStateExternally) {
      props && props.handleChange(name, value);
    } else {
      setState({ ...state, dirty: true, data: savedChanges });
    }
    propogateAfterChange(savedChanges);
  };
  const handleMultiSelectChange = (inputEl, item) => async e => {
    const data = props.manageStateExternally ? props.entity : state.data;
    const {
      target: { name, checked }
    } = e;
    const acceptChange = await propogateBeforeChange({ name, value: item, data });
    if (acceptChange === false) return;
    const existingElements = data[inputEl.name] || [];
    const entities = checked
      ? [...existingElements, item]
      : existingElements.filter(x => !compareMultiSelectValues(inputEl, x, item));

    if (props.manageStateExternally) {
      props && props.handleChange(inputEl.name, entities);
    } else {
      setState({
        ...state,
        dirty: true,
        data: {
          ...data,
          [inputEl.name]: entities
        }
      });
    }
  };
  const handleSubmit = async () => {
    const data = props.manageStateExternally ? props.entity : state.data;
    const { internalFormControls } = state;
    const { formControls, inputs } = props;
    const errors = validate({ ...data }, internalFormControls);
    if (errors) {
      setState({ ...state, internalFormControls: _.cloneDeep(errors) });
    } else {
      const mutatedEntity = await props.handleSubmit({ ...data });
      const internalFormControls = formControls
        ? _.cloneDeep(formControls)
        : inputs.reduce((acc, x) => ({ ...acc, [x.name]: _.cloneDeep(x.formControl) }), {});
      if (mutatedEntity) {
        setState({ ...state, data: mutatedEntity, internalFormControls });
      } else setState({ ...state, internalFormControls });
    }
  };
  const renderInput = x => {
    const { classes } = props;
    const { internalFormControls } = state;
    const data = props.manageStateExternally ? props.entity : state.data;

    // TODO REFACTOR
    // as use cases expand, this should probably be refactored to use some sort of map that modularizes the jsx better
    if (x.type === undefined) throw new Error('input type cannot be undefined!');
    if (x.type === TEXT_TYPE || x.type === NUMBER_TYPE) {
      return (
        <Grid {...getGridLayoutOptions(x.layoutOptions)} item key={x.name}>
          <TextField
            disabled={x.isDisabled ? x.isDisabled(x, data) : false}
            error={internalFormControls[x.name]?.err}
            className={classes.input}
            name={x.name}
            type={x.type || 'text'}
            label={x.label}
            value={sanitize(data[x.name]) || ''}
            onChange={handleChange}
          />
          <MaybeControlLabel
            visible={internalFormControls[x.name]?.err}
            message={internalFormControls[x.name]?.message}
          />
        </Grid>
      );
    }

    if (x.type === DATE_TYPE) {
      return (
        <Grid {...getGridLayoutOptions(x.layoutOptions)} item key={x.name}>
          <TextField
            name={x.name}
            disabled={x.isDisabled ? x.isDisabled(x, data) : false}
            error={internalFormControls[x.name]?.err}
            className={classes.input}
            id="date"
            label={x.label}
            value={sanitize(data[x.name]) || ''}
            onChange={handleChange}
            type="date"
            InputLabelProps={{
              shrink: true
            }}
          />
          <MaybeControlLabel
            visible={internalFormControls[x.name]?.err}
            message={internalFormControls[x.name]?.message}
          />
        </Grid>
      );
    }
    if (x.type === JSON_TYPE) {
      const value =
        typeof data[x.name] === 'object' && data[x.name] !== null
          ? JSON.stringify(data[x.name])
          : data[x.name];
      return (
        <Grid {...getGridLayoutOptions(x.layoutOptions)} item key={x.name}>
          <TextField
            disabled={x.isDisabled ? x.isDisabled(x, data) : false}
            error={internalFormControls[x.name]?.err}
            className={classes.inputFullWidth}
            name={x.name}
            type={'text'}
            label={x.label}
            value={sanitize(value)}
            onChange={handleChange}
          />
          <MaybeControlLabel
            visible={internalFormControls[x.name]?.err}
            message={internalFormControls[x.name]?.message}
          />
        </Grid>
      );
    }
    if (x.type === SELECT_TYPE) {
      return (
        <Grid item {...getGridLayoutOptions(x.layoutOptions)} key={x.name}>
          <FormControl className={classes.input}>
            <InputLabel htmlFor={x.name}>{x.label}</InputLabel>
            <Select
              disabled={x.isDisabled ? x.isDisabled(x, data) : false}
              error={internalFormControls[x.name]?.err}
              value={sanitize(data[x.name])}
              onChange={handleChange}
              inputProps={{
                name: x.name,
                id: `${x.name}-id`
              }}
            >
              {x.values.map((y, yIndex) => (
                <MenuItem key={yIndex} value={getSelectValue(x, y)}>
                  {getSelectDisplay(x, y)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <MaybeControlLabel
            visible={internalFormControls[x.name]?.err}
            message={internalFormControls[x.name]?.message}
          />
        </Grid>
      );
    }
    if (x.type === MULTI_SELECT_TYPE) {
      return (
        <Grid item {...getGridLayoutOptions(x.layoutOptions)} key={x.name}>
          <FormControl className={classes.input}>
            <InputLabel
              error={internalFormControls[x.name]?.err}
              htmlFor="select-multiple-checkbox"
            >
              {x.label}
            </InputLabel>
            <Select
              disabled={x.isDisabled ? x.isDisabled(x, data) : false}
              error={internalFormControls[x.name]?.err}
              multiple
              value={sanitize(data[x.name]) || []}
              input={<Input id="select-multiple-checkbox" />}
              renderValue={(selected: any) =>
                sanitizeMultiSelectDisplay(
                  selected.map(item => getSelectDisplay(x, item)).join(', ')
                )
              }
              inputProps={{
                name: x.name,
                id: `${x.name}-id`
              }}
            >
              {x.values.map((y, index) => (
                <MenuItem key={index} value={getSelectValue(x, y)}>
                  <Checkbox
                    onChange={handleMultiSelectChange(x, y)}
                    checked={
                      (data[x.name] || []).find(item => compareMultiSelectValues(x, item, y))
                        ? true
                        : false
                    }
                  />
                  <ListItemText>{getSelectDisplay(x, y)}</ListItemText>
                </MenuItem>
              ))}
            </Select>
            <MaybeControlLabel
              visible={internalFormControls[x.name]?.err}
              message={internalFormControls[x.name]?.message}
            />
          </FormControl>
        </Grid>
      );
    }
  };
  const render = () => {
    const {
      inputs,
      formControls,
      entity,
      entityName,
      formType = 'editor',
      handleCancel,
      classes,
      manageStateExternally = false
    } = props;
    const { internalFormControls, data } = state;
    if (internalFormControls === null) {
      // grab copy of formControls from props, then manage internally
      const defaultData = inputs.reduce(
        (acc, x) => ({ ...acc, [x.name]: getInitialDataValue(x) }),
        {}
      );
      const internalFormControls = formControls
        ? _.cloneDeep(formControls)
        : inputs.reduce((acc, x) => ({ ...acc, [x.name]: _.cloneDeep(x.formControl) }), {});

      setState({
        ...state,
        internalFormControls,
        data: defaultData
      });
      return <div></div>;
    }
    // set passed down entity value to form so can edit
    // ensures passed-down entity value will override state only if:
    // local data is falsy OR local data id does not match passed down id OR on first load (first load captures transient items)
    if (
      !manageStateExternally &&
      entity &&
      (!data || entity.id !== (data as any).id || state.controlledDataIsPristine)
    ) {
      setState({
        ...state,
        data: entity,
        controlledDataIsPristine: false
      });
    }

    if (formType === 'editor') {
      return (
        <React.Fragment>
          <div style={{ marginTop: 25 }}>
            <SaveCancelButtonToolbar handleSave={handleSubmit} handleCancel={handleCancel} />
          </div>
          <Paper elevation={1} className={classes.paper}>
            <Grid container spacing={2} className={classes.editor}>
              {inputs.map(renderInput)}
            </Grid>
          </Paper>
        </React.Fragment>
      );
    } else if (formType === 'expansion-panel') {
      return (
        <ExpansionPanel defaultExpanded={false}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h5" component="h3">
              Filters
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails style={{ display: 'inherit' }}>
            <Paper elevation={1} className={classes.paper}>
              <Grid container spacing={2} className={classes.editor}>
                {inputs.map(renderInput)}
              </Grid>
              <div style={{ marginTop: 25 }}>
                <SaveCancelButtonToolbar handleSave={handleSubmit} handleCancel={handleCancel} />
              </div>
            </Paper>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      );
    } else if (formType === 'blank') {
      return (
        <React.Fragment>
          <Grid container spacing={2} className={classes.editor}>
            {inputs.map(renderInput)}
          </Grid>
        </React.Fragment>
      );
    } else if (formType === 'modal') {
      return (
        <Dialog fullWidth={true} maxWidth={'sm'} open={true}>
          <DialogTitle style={{ textAlign: 'center', margin: 'auto' }}>
            Create {entityName}
          </DialogTitle>
          <DialogContent>{inputs.map(renderInput)}</DialogContent>
          <DialogActions>
            {/* entity && entity.id ? 'Update' : 'Create' */}
            <Button color="primary" onClick={handleSubmit}>
              {'Save'}
            </Button>
            <Button onClick={handleCancel}>Cancel</Button>
          </DialogActions>
        </Dialog>
      );
    } else if (formType === 'finder') {
      return (
        <Grid container>
          <Grid container spacing={2} className={classes.editor}>
            {inputs.map(renderInput)}
            <Grid item xs={6} sm={3} lg={2}>
              <Button variant="contained" color="secondary" type="button" onClick={handleSubmit}>
                Get {props.entityName}s
              </Button>
            </Grid>
          </Grid>
        </Grid>
      );
    }
  };
  return render();
}
export default compose(withStyles(styles as any))(LwtForm);
