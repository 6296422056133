import React from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

const SaveCancelButtonToolbar = ({ handleSave, handleCancel }) => (
  <Grid style={{ marginBottom: 20 }}>
    <Button
      style={{ marginRight: 10 }}
      variant="contained"
      color="secondary"
      type="button"
      onClick={handleSave}
    >
      Submit
    </Button>
    <Button variant="contained" type="button" onClick={handleCancel}>
      Cancel
    </Button>
  </Grid>
);
export default SaveCancelButtonToolbar;
