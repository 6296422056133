import ROLES from './roles';
const config = require('../config');

const getServerUrl = () => config.serverUrl;

export const CONSTANT = {
  ...ROLES,
  ENV: config.ENV,
  CONFIG: config,
  API_URL: getServerUrl(),
  GRAPHQL_URL: `${getServerUrl()}${config.graphqlRoute}`,
  REST_URL: `${getServerUrl()}${config.restRoute}`,
  APPLICATION_NAME: 'GF_KIDS_ADMIN'
};
