import { gql } from '@apollo/client'
import { BaseChildFragment } from './child-fragment';

export const BaseChildAlertFragment = gql`
  fragment BaseChildAlert on ChildAlert {
    id
    message
    isAcknowledged
    surveyCompletedId
    createdOnDate
    child {
      ...BaseChild
    }
  }
  ${BaseChildFragment}
`;
