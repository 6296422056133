import React from 'react';

export const MaybeControlLabel = ({ visible, message }) => {
  return visible ? (
    <div style={{ color: '#f44336', fontSize: '10px', paddingTop: '3px', paddingLeft: '10px' }}>
      {message}
    </div>
  ) : (
    <div></div>
  );
};
