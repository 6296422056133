import React from 'react';
import { Link } from 'react-router-dom';
import { calculateDropdownYears } from '../../../../util/years-util';
import { sort, dateSort } from '../../../../util/sort-util';
import { maybeUser, maybeDate } from '../../../../util/maybe';

export const IMAGE_VIOLATIONS_COLUMNS = [
  {
    title: 'Last Image Date',
    field: 'lastImageDate',
    render: rowData => <span>{maybeDate(rowData.lastImageDate)}</span>
  },
  {
    title: 'Child Name',
    field: 'name',
    render: rowData => <Link to={`/child/${rowData.id}`}>{rowData.name}</Link>
  },
  {
    title: 'Care Worker Name',
    field: 'user',
    customSort: sort('user.firstName'),
    render: rowData => <span>{maybeUser(rowData.user)}</span>,
    customFilterAndSearch: (search, rowData) => {
      return rowData.user && rowData.user.lastName.toLowerCase().includes(search);
    }
  },
  {
    title: 'Country Name',
    customSort: sort('country.name'),
    render: rowData => <span>{rowData.country?.name}</span>
  },
  {
    title: 'Village Name',
    customSort: sort('village.name'),
    render: rowData => <span>{rowData.village?.name}</span>
  }
];

export const MESSAGE_VIOLATIONS_COLUMNS = [
  {
    title: 'Last Message Date',
    field: 'lastMessageDate',
    render: rowData => <span>{maybeDate(rowData.lastMessageDate)}</span>
  },
  {
    title: 'Child Name',
    field: 'name',
    render: rowData => <Link to={`/child/${rowData.id}`}>{rowData.name}</Link>
  },
  {
    title: 'Care Worker Name',
    field: 'user',
    customSort: sort('user.firstName'),
    render: rowData => <span>{maybeUser(rowData.user)}</span>,
    customFilterAndSearch: (search, rowData) => {
      return rowData.user && rowData.user.lastName.toLowerCase().includes(search);
    }
  },
  {
    title: 'Country Name',
    customSort: sort('country.name'),
    render: rowData => <span>{rowData.country?.name}</span>
  },
  {
    title: 'Village Name',
    customSort: sort('village.name'),
    render: rowData => <span>{rowData.village?.name}</span>
  }
];

export const CHECKUP_VIOLATIONS_COLUMNS = [
  {
    title: 'Last Checkup Date',
    field: 'lastCheckupDate',
    render: rowData => <span>{maybeDate(rowData.lastCheckupDate)}</span>,
    customSort: dateSort('lastCheckupDate')
  },
  {
    title: 'Child Name',
    field: 'name',
    render: rowData => <Link to={`/child/${rowData.id}`}>{rowData.name}</Link>
  },
  {
    title: 'Care Worker',
    field: 'user',
    render: rowData => <span>{maybeUser(rowData.user)}</span>,
    customFilterAndSearch: (search, rowData) => {
      return rowData.user && rowData.user.lastName.toLowerCase().includes(search);
    },
    customSort: sort('user.firstName')
  },
  {
    title: 'Country Name',
    render: rowData => <span>{rowData.country?.name}</span>,
    customSort: sort('country.name')
  },
  {
    title: 'Village Name',
    render: rowData => <span>{rowData.village ? rowData.village?.name : ''}</span>,
    customSort: sort('village.name')
  }
];

export const SURVEY_VIOLATIONS_COLUMNS = [
  {
    title: 'Last Survey Date',
    render: rowData => <span>{maybeDate(rowData.lastSurveyDate)}</span>,
    customSort: dateSort('lastSurveyDate')
  },
  {
    title: 'Child Name',
    field: 'name',
    render: rowData => <Link to={`/child/${rowData.id}`}>{rowData.name}</Link>
  },
  {
    title: 'Care Worker',
    field: 'user',
    render: rowData => <span>{maybeUser(rowData.user)}</span>,
    customFilterAndSearch: (search, rowData) => {
      return rowData.user && rowData.user.lastName.toLowerCase().includes(search);
    },
    customSort: sort('user.firstName')
  },
  {
    title: 'Country Name',
    render: rowData => <span>{rowData.country?.name}</span>,
    customSort: sort('country.name')
  },
  {
    title: 'Village Name',
    render: rowData => <span>{rowData.village ? rowData.village?.name : ''}</span>,
    customSort: sort('village.name')
  }
];

export const getDefaultChartData = () => ({
  '0': 0,
  '1': 0,
  '2': 0,
  '3': 0,
  '4': 0,
  '5': 0,
  '6': 0,
  '7': 0,
  '8': 0,
  '9': 0,
  '10': 0,
  '11': 0
});

export const YEARS = calculateDropdownYears();

export const TABS = {
  SURVEYS: 0,
  CHECKUPS: 1,
  SYNCS: 2
};

export const TABS_VIOLATIONS = {
  // SURVEYS: 0,
  CHECKUPS: 0,
  MESSAGES: 1,
  IMAGES: 2
};
