const NOW_YEAR = new Date().getFullYear();

export const calculateDropdownYears = (BASELINE_YEAR = 2018) => {
  if (NOW_YEAR === BASELINE_YEAR) return [NOW_YEAR];

  const years = [BASELINE_YEAR];
  const difference = NOW_YEAR - BASELINE_YEAR;
  for (let i = 0; i < difference; i++) {
    years.push(BASELINE_YEAR + i + 1);
  }
  return years;
};

export const now = () => new Date().getFullYear();
