import React from 'react';
import Button from '@material-ui/core/Button';

const localStyles = {
  footerButton: {
    display: 'flex',
    justifyContent: 'flex-start'
  }
};

const ButtonToolbar = ({
  handleSubmit,
  handleCancel = null,
  submitButtonText = '',
  style = {},
  children = null,
  disabled = false
}) => {
  if (!handleSubmit && !handleCancel)
    throw new Error(`ButtonToolbar requires at least one click handler!`);
  return (
    <div style={{ ...localStyles.footerButton, ...style }}>
      {children}
      {!!handleSubmit && (
        <Button
          onClick={handleSubmit}
          color="secondary"
          variant="contained"
          type="submit"
          style={{ marginRight: 10 }}
          disabled={disabled}
        >
          {submitButtonText || 'Submit'}
        </Button>
      )}
      {!!handleCancel && (
        <Button type="reset" onClick={handleCancel} variant="contained">
          Clear
        </Button>
      )}
    </div>
  );
};

export default ButtonToolbar;
