import React, { useState, useEffect } from 'react';
import { stringify, parse } from 'query-string';
import CommonTable from '../../common/CommonTable';
import ReportsPageLoadQuery from '../../../graphql/Reports/page-load-query';
import { getColumns, getInputs, getNone } from './reports-config';
import Form, { getInitialDataValue } from '../../common/Form';
import { loadInputValues } from '../../../util/load-input-values';
import getSurveyTemplateQuery from '../../../graphql/ManageSurvey/getSurveyTemplateQuery';
import ReportsService from './reports-service';
import { useQuery, useApolloClient } from '@apollo/client';
import { useHistory, useRouteMatch } from 'react-router';
import { getName } from './reports-config';
import { exportCsv } from 'util/export-util';
// import ButtonToolbar from 'components/common/ButtonToolbar';
// import reportsService from './reports-service';

const sanitize = value => {
  if (typeof value === 'string' && value !== '') {
    const candidateNumber = +value;

    if (isNaN(candidateNumber)) {
      return value;
    }

    return candidateNumber;
  }

  if (Array.isArray(value)) {
    return value;
  }

  return null;
};

const makeSites = (countryId, allSites) => allSites.filter(x => x.countryId === countryId);
const makeRegions = (siteId, allRegions) => allRegions.filter(x => x.siteId === siteId);
const makeVillages = (regionId, allVillages) => allVillages.filter(x => x.regionId === regionId);

const getCategories = (domainId, surveyTemplate) => {
  const domain = surveyTemplate.domains.find(x => x.id === domainId);

  return domain && domain.categories ? domain.categories : [];
};
const getOptions = (domainId, categoryId, surveyTemplate) => {
  const categories = getCategories(domainId, surveyTemplate);

  return categories.find(x => x.id === categoryId).options;
};
const getDefaultFormState = inputs =>
  inputs.reduce((acc, x) => ({ ...acc, [x.name]: getInitialDataValue(x) }), {});

function SurveyAnswers(props) {
  const { data } = useQuery(ReportsPageLoadQuery, {
    variables: { role: 'CAREWORKER', surveyTemplateId: null }
  });
  const history = useHistory();
  const match = useRouteMatch();
  const client = useApolloClient();
  const [tableLoading, setTableLoading] = useState(false);
  const [inputs, setInputs] = useState([]);
  const [formState, setFormState] = useState({
    ...getDefaultFormState(getInputs('survey-answers')),
    type: 'survey-answers'
  });
  const [activeSurveyTemplateState, setActiveSurveyTemplateState] = useState(null);
  const [results, setResults] = useState([]);
  const {
    usersByRole = [],
    allRegionsByPermissions = [],
    allSitesByPermissions = [],
    allVillagesByPermissions = [],
    allCountriesByPermissions = [],
    surveyTemplates = []
  } = data ?? ({} as any);

  const getAndSetActiveSurveyTemplate = async surveyTemplateId => {
    const {
      data: { surveyTemplate: nextSurveyTemplate }
    } = await client.query({
      query: getSurveyTemplateQuery,
      variables: { surveyTemplateId }
    });
    setActiveSurveyTemplateState(nextSurveyTemplate);

    return nextSurveyTemplate;
  };

  const handleCancel = () => {
    const type = 'survey-answers';
    const nextFormState = {
      ...getDefaultFormState(getInputs(type)),
      type: type
    };
    setFormState(nextFormState);
    history.push(`${match.url}`);
  };

  const handleSubmit = async () => {
    history.push(`${match.url}?${stringify(formState)}`);
  };

  const buildData = data => {
    let jsonData = [];

    if (formState.type === 'checkups') {
      jsonData = data.map(x => ({
        'Created on Date': x.createdOnDate,
        Village: x.child.village ? x.child.village.name : '',
        Child: x.child.name,
        'Care Worker': getName(x.child.user)
      }));
    } else if (formState.type === 'survey-violations') {
      jsonData = data.map(x => ({
        'Last Survey Date': x.lastSurveyDate,
        Village: x.village ? x.village.name : '',
        Child: x.name,
        'Care Worker': getName(x.user)
      }));
    } else if (formState.type === 'survey-answers') {
      jsonData = data.map(x => ({
        'Created on Date': x.createdOnDate,
        Village: x.child.village ? x.child.village.name : '',
        Child: x.child.name,
        Domain: x.domain.name,
        Category: x.category.name,
        Score: x.scoreValue,
        'Care Worker': getName(x.child.user),
        'Survey Care Worker': getName(x.survey_completed.user)
      }));
    }

    return jsonData;
  };

  // TODO: this needs to be cleaned up
  const exportData = (columns, data, type = 'csv') => {
    const jsonData = buildData(data);
    const filename = `gfdata-export-report--${formState.type}.csv`;
    exportCsv(jsonData, filename);
  };

  // const handleDownloadReport = () => {
  //   const jsonData = buildData(results);
  //   reportsService.downloadReport(jsonData);
  // };

  const handleChange = async (name, value) => {
    let formStateDependents = {};
    let inputsStateDependents = {};
    let nextSurveyTemplate = null;

    switch (name) {
      case 'type':
        inputsStateDependents = {
          supervisorId: [getNone('name', 'id'), ...usersByRole],
          surveyTemplateId: surveyTemplates,
          countryId: allCountriesByPermissions,
          ...(formState.countryId && {
            siteId: makeSites(formState.countryId, allSitesByPermissions)
          }),
          ...(formState.siteId && {
            regionId: makeRegions(formState.siteId, allRegionsByPermissions)
          }),
          ...(formState.regionId && {
            villageId: makeVillages(formState.regionId, allVillagesByPermissions)
          }),
          domainId: [getNone('name', 'id'), ...(data.surveyTemplate?.domains ?? [])],
          ...(formState.domainId && {
            categoryId: getCategories(formState.domainId, data.surveyTemplate)
          }),
          ...(formState.categoryId && {
            optionId: getOptions(formState.domainId, formState.categoryId, data.surveyTemplate)
          })
        };

        setResults([]);
        setInputs(loadInputValues(getInputs(value), inputsStateDependents));
        setFormState({
          ...formState,
          ...formStateDependents,
          [name]: value
        });

        return;
      case 'surveyTemplateId':
        nextSurveyTemplate = await getAndSetActiveSurveyTemplate(value);
        inputsStateDependents = {
          domainId: nextSurveyTemplate?.domains ?? [],
          categoryId: null,
          optionId: null
        };
        break;
      case 'countryId':
        formStateDependents = {
          supervisorId: null,
          siteId: null,
          regionId: null,
          villageId: null
        };
        inputsStateDependents = {
          supervisorId: usersByRole.filter(
            x => x.countries && x.countries.find(y => y.id === value)
          ),
          siteId: makeSites(value, allSitesByPermissions),
          regionId: [],
          villageId: []
        };
        break;
      case 'siteId':
        formStateDependents = {
          regionId: null,
          villageId: null
        };
        inputsStateDependents = {
          regionId: makeRegions(value, allRegionsByPermissions),
          villageId: []
        };
        break;
      case 'regionId':
        formStateDependents = {
          villageId: null
        };
        inputsStateDependents = {
          villageId: makeVillages(value, allVillagesByPermissions)
        };
        break;
      case 'villageId':
        // do nothing right now
        break;
      case 'domainId':
        formStateDependents = {
          categoryId: null,
          optionId: null
        };
        inputsStateDependents = {
          categoryId: getCategories(value, activeSurveyTemplateState) || [],
          optionId: []
        };
        break;
      case 'categoryId':
        formStateDependents = {
          optionId: null
        };
        inputsStateDependents = {
          optionId: getOptions(formState.domainId, value, activeSurveyTemplateState) || []
        };
        break;
      case 'optionId':
        // do nothing right now
        break;
    }

    setFormState({
      ...formState,
      ...formStateDependents,
      [name]: value
    });
    setInputs(loadInputValues(inputs, inputsStateDependents));
  };

  useEffect(() => {
    if (data) {
      const searchParams = parse(history.location.search) || {};
      const sanitizedParams: any = Object.keys(searchParams).reduce((acc, x) => {
        acc[x] = sanitize(searchParams[x]);
        return acc;
      }, {});

      if (sanitizedParams.scoreValue && !Array.isArray(sanitizedParams.scoreValue)) {
        sanitizedParams.scoreValue = [sanitizedParams.scoreValue].map(x => +x);
      }

      const users = sanitizedParams.countryId
        ? usersByRole.filter(
            x => x.countries && x.countries.find(y => y.id === sanitizedParams.countryId)
          )
        : usersByRole;

      setFormState(formState => {
        const nextFormState = {
          ...formState,
          ...sanitizedParams,
          scoreValue: sanitizedParams.scoreValue ? sanitizedParams.scoreValue.map(x => +x) : []
        };

        setInputs(
          loadInputValues(getInputs(nextFormState.type), {
            supervisorId: [getNone('name', 'id'), ...users],
            surveyTemplateId: surveyTemplates,
            countryId: allCountriesByPermissions,
            ...(nextFormState.countryId && {
              siteId: makeSites(nextFormState.countryId, allSitesByPermissions)
            }),
            ...(nextFormState.siteId && {
              regionId: makeRegions(nextFormState.siteId, allRegionsByPermissions)
            }),
            ...(nextFormState.regionId && {
              villageId: makeVillages(nextFormState.regionId, allVillagesByPermissions)
            }),
            domainId: [getNone('name', 'id'), ...(data.surveyTemplate?.domains ?? [])],
            ...(nextFormState.domainId && {
              categoryId: getCategories(nextFormState.domainId, data.surveyTemplate)
            }),
            ...(nextFormState.categoryId && {
              optionId: getOptions(
                nextFormState.domainId,
                nextFormState.categoryId,
                data.surveyTemplate
              )
            })
          })
        );

        ReportsService.getResults(nextFormState, { limit, type: nextFormState.type }).then(
          results => {
            setResults(results);
            setTableLoading(false);
          }
        );

        return nextFormState;
      });
      setActiveSurveyTemplateState(data.surveyTemplate);
      setTableLoading(true);

      const limit = history.location.search ? undefined : 100;
    }
  }, [
    data,
    history.location.search,
    allCountriesByPermissions,
    allRegionsByPermissions,
    allSitesByPermissions,
    allVillagesByPermissions,
    surveyTemplates,
    usersByRole
  ]);

  return (
    <div>
      <Form
        {...props}
        formType={'expansion-panel'}
        handleChange={handleChange}
        handleCancel={handleCancel}
        handleSubmit={handleSubmit}
        entityName={'Survey Answers'}
        entity={formState}
        manageStateExternally={true}
        inputs={inputs}
      />
      <div style={{ marginBottom: 40 }}>
        {/* <div style={{ margin: 30 }}>
          <ButtonToolbar submitButtonText="Download Report" handleSubmit={handleDownloadReport} />
        </div> */}
        <CommonTable
          style={{ marginTop: 30 }}
          title={
            <span>
              Top {results.length} results{' '}
              {results.length >= 6000 ? '(narrow search to see rest of results)' : ''}
            </span>
          }
          columns={getColumns(formState.type)}
          data={results}
          options={{
            search: false,
            exportButton: true,
            exportCsv: (columns, data) => exportData(columns, data)
          }}
          isLoading={tableLoading}
        />
      </div>
    </div>
  );
}

export default SurveyAnswers;
